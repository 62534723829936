import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPersonas } from '../../reducers/persona_slice';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { Alert } from 'react-bootstrap';
import SelectPersona from '../persona/SelectPersona';
import { addUsuario, fetchUsuarios, modifyUsuario } from '../../reducers/usuario_slice';
import { fetchCajas } from '../../reducers/caja_slice'; // Importar el thunk para obtener las cajas

const initialState = {
    id_persona: "",
    persona: null,
    usuario: "",
    clave_acceso: "",
    estado_sql: "1",
    caja: "", // Agregado
    cuentacaja: "", // Agregado
    cambio:1
};

const initialFormDetalles = {
    title: "Nuevo Usuario",
    headerColor: "#254159",
    buttonText: "Crear"
};

const FormUsuario = ({ currentUsuario, showModal, handleClose }) => {
    const [datos, setDatos] = useState(initialState);
    const [error, setError] = useState('');
    const [isModified, setIsModified] = useState(false);
    const [formDetalles, setFormDetalles] = useState(initialFormDetalles);
    const [isSending, setIsSending] = useState(false);

    const dispatch = useDispatch();

    const personas = useSelector(state => state.persona.personas);
    const persona = useSelector(state => state.persona.current);
    const usuarios = useSelector(state => state.usuario.usuarios);
    const cajas = useSelector(state => state.caja.cajas); // Obtener las cajas desde el estado
    const requestStatus = useSelector(state => state.usuario.statusSend);
    const errorInRequest = useSelector(state => state.usuario.error);

    useEffect(() => {
        if (showModal) {
            dispatch(fetchPersonas());
            dispatch(fetchCajas()); // Llamar al thunk para obtener las cajas
            if (currentUsuario) {
                dispatch(fetchUsuarios());
                setFormDetalles({
                    title: "Editar datos del usuario",
                    headerColor: "#255957",
                    buttonText: "Actualizar"
                });
                setDatos({ ...currentUsuario, clave_acceso: "" });
                setIsModified(false);
            } else {
                dispatch(fetchUsuarios());
                resetForm();
            }
        }
    }, [currentUsuario, showModal, dispatch]);

    useEffect(() => {
        if (persona) {
            setDatos(prev => ({
                ...prev,
                id_persona: persona.id,
                persona
            }));
        }
    }, [persona]);

    const handleCajaSelect = (e) => {
        const selectedCaja = cajas.find(caja => caja.id === parseInt(e.target.value, 10));
        if (selectedCaja) {
            setDatos(prev => ({
                ...prev,
                caja: selectedCaja.nrocaja,
                cuentacaja: selectedCaja.numero_cuenta
            }));
            setIsModified(true);
        }
    };

    const handleSubmit = e => {
        e.preventDefault();
        if (isModified) {
            setError('');
            setIsSending(true);
            if (currentUsuario) {
                const { persona, id_persona, ...userData } = datos;
                dispatch(modifyUsuario({ id: currentUsuario.id, usuario: userData })).then(response => {
                    if (response.error || response.payload.error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.payload.error || response.error.message || 'Error al modificar el usuario.',
                        });
                    } else {
                        handleClose();
                        dispatch(fetchUsuarios());
                    }
                    setIsSending(false);
                });
            } else {
                dispatch(addUsuario(datos)).then(response => {
                    if (response.error || response.payload.error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.payload.error || response.error.message || 'Error al crear el usuario.',
                        });
                    } else {
                        handleClose();
                        dispatch(fetchUsuarios());
                    }
                    setIsSending(false);
                });
            }
        } else {
            handleClose();
        }
    };

    const resetForm = () => {
        setDatos(initialState);
        setFormDetalles(initialFormDetalles);
        setError('');
        setIsModified(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDatos((prevDatos) => ({ ...prevDatos, [name]: value }));
        setIsModified(true);
    };

    const handleSelect = (name, object) => {
        const objetoId = object ? object.id : "";
        setIsModified(true);
        setDatos((prevDatos) => ({ ...prevDatos, [`id_${name}`]: objetoId, [name]: object }));
    };

    const personasConUsuario = personas.filter(persona => usuarios.some(usuario => usuario.id_persona === persona.id));

    return (
        <>
            <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
                <Modal.Header closeButton style={{ backgroundColor: formDetalles.headerColor, color: 'white' }}>
                    <Modal.Title>{formDetalles.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error && (
                        <Alert variant="danger">
                            {error}
                        </Alert>
                    )}
                    <Form onSubmit={handleSubmit} className='normal-form'>
                        <Form.Group className="row">
                            <div className="col-md-4 modal-input">
                                <Form.Label>Usuario</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="usuario"
                                    placeholder="Nombre de usuario"
                                    value={datos.usuario}
                                    onChange={handleInputChange}
                                    required
                                    disabled={isSending}
                                    autoComplete="off"
                                />
                            </div>
                            <div className="col-md-4 modal-input">
                                <Form.Label>Clave de acceso</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="clave_acceso"
                                    placeholder="Nueva contraseña"
                                    value={datos.clave_acceso}
                                    onChange={handleInputChange}
                                    required={!currentUsuario ? true : false}
                                    disabled={isSending}
                                    autoComplete="new-password"
                                />
                            </div>
                            <div className="col-md-4 modal-input">
                                <Form.Label>Estado</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="estado_sql"
                                    value={datos.estado_sql}
                                    onChange={handleInputChange}
                                    disabled={isSending}
                                >
                                    <option value="1">Activo</option>
                                    <option value="0">Inactivo</option>
                                </Form.Control>
                            </div>
                        </Form.Group>
                        <Form.Group className="row">
                            <div className="col-md-4 modal-input">
                                <Form.Label>Caja</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="caja"
                                    value={datos.caja || ''}
                                    onChange={handleCajaSelect}
                                    disabled={isSending}
                                >
                                    <option value="" disabled>Seleccione una caja</option>
                                    {cajas.map(caja => (
                                        <option key={caja.id} value={caja.id}>
                                            {caja.nrocaja}
                                        </option>
                                    ))}
                                </Form.Control>
                            </div>
                        </Form.Group>
                        <SelectPersona labelText="Persona"
                            personaSelected={datos.persona}
                            onSelect={handleSelect}
                            simplePersona={true}
                            excludeList={personasConUsuario}
                            actualizarListaFn={fetchPersonas}
                            permitirEdicion={!currentUsuario} />
                        <div className="d-flex justify-content-between mt-5">
                            <button className="button-mine btn-cancel-outline" onClick={handleClose} type='button'>
                                Cancelar
                            </button>
                            <button className="button-mine btn-success-outline" type="submit" disabled={!isModified}>
                                <i className="bi bi-check-circle"></i> {formDetalles.buttonText}
                            </button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default FormUsuario;
