import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchValoradoVens } from '../../reducers/valoradoVen_slice';
import { fetchValorados } from '../../reducers/valorado_slice';
import { fetchValoradoDets } from '../../reducers/valoradoDet_slice';
import { fetchReciboById } from '../../reducers/recibo_slice';
import Table from 'react-bootstrap/Table';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import CustomPrintModal2 from '../UI/CustomPrintModal2';
import ActionButton from '../UI/Buttons/ActionButton';
import { formaFechaI, formatMonetaryValue, numeroALetras } from '../../util/valueCalculator';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ValoradoVenForm from './ValoradoVenForm';
import ImprimirRecibo from '../recibo/ImprimirRecibo';
import { fetchCajasDiariasByUsuario } from "../../reducers/caja_slice";
import { useNavigate } from 'react-router-dom';

const ValoradoVenList = () => {
    const dispatch = useDispatch();
    const userId = useSelector(state => state.sesion.id);
    const { nombre, rolActivo, roles,id } = useSelector(state => state.sesion);
    const cajaState = useSelector(state => state.caja || { cajas: [] });
    const cajasDiarias = cajaState.cajasDiarias || [];
    const idCajaDiaria = useSelector(state => state.caja.idCajaDiaria);
    const valoradoVenState = useSelector(state => state.valoradoVen || { valoradoVens: [] });
    const valoradoVens = valoradoVenState.valoradoVens || [];
    const valorados = useSelector(state => state.valorado.valorados || []);
    const valoradoDets = useSelector(state => state.valoradoDet.valoradoDets || []);

    const [showModal, setShowModal] = useState(false);
    const [currentValoradoVen, setCurrentValoradoVen] = useState(null);
    const [showFormModal, setShowFormModal] = useState(false);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [printContent, setPrintContent] = useState({ head: [], body: [] });
    const [reciboData, setReciboData] = useState(null);
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: 'fecha', direction: 'desc' });
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    const currentYear = new Date().getFullYear();
    const currentMonth = String(new Date().getMonth() + 1).padStart(2, '0');
    const currentDay = String(new Date().getDate()).padStart(2, '0');
    const [startDate, setStartDate] = useState(`${currentYear}-01-01`);
    const [endDate, setEndDate] = useState(`${currentYear}-${currentMonth}-${currentDay}`);

    useEffect(() => {
        dispatch(fetchValoradoVens());
        dispatch(fetchValorados());
        dispatch(fetchValoradoDets());
        dispatch(fetchCajasDiariasByUsuario(id)); // Llamar al thunk para cajas diarias
    }, [dispatch,id]);

    const getValoradoDescripcion = (id_vd) => {
        const valoradoDet = valoradoDets.find(vd => vd.id === id_vd);
        if (!valoradoDet) {
            return 'Descripción no disponible';
        }
        const valorado = valorados.find(v => v.id === valoradoDet.id_valorado);
        return valorado ? valorado.descripcion : 'Descripción no disponible';
    };

    const filteredValoradoVens = useMemo(() => {
        return valoradoVens.filter(valoradoVen => {
            const matchesSearch = getValoradoDescripcion(valoradoVen.id_vd).toLowerCase().includes(searchTerm.toLowerCase());
            const matchesDateRange =
                (!startDate || new Date(valoradoVen.fecha) >= new Date(startDate)) &&
                (!endDate || new Date(valoradoVen.fecha) <= new Date(endDate));
            return matchesSearch && matchesDateRange;
        });
    }, [valoradoVens, searchTerm, startDate, endDate]);

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const handleViewDetail = (valoradoVen) => {
        setCurrentValoradoVen(valoradoVen);
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
        setCurrentValoradoVen(null);
    };

    const handleCloseFormModal = async (idRecibo) => {
        setShowFormModal(false);
        dispatch(fetchValoradoVens());
        try {
            const recibo = await dispatch(fetchReciboById(idRecibo)).unwrap();
            const totalLiteral = numeroALetras(recibo.monto);
            setReciboData({
                recibo,
                reciboDetalles: recibo.reciboDets,
                totalLiteral
            });
            setShowPrintModal(true);
        } catch (error) {
            Swal.fire('Error', 'No se pudo obtener el recibo', 'error');
        }
    };

    const handleCreate = () => {
        setCurrentValoradoVen(null);
        setShowFormModal(true);
    };

    const handleViewRecibo = async (id_recibo) => {
        try {
            const recibo = await dispatch(fetchReciboById(id_recibo)).unwrap();
            const totalLiteral = numeroALetras(recibo.monto);
            setReciboData({
                recibo,
                reciboDetalles: recibo.reciboDets,
                totalLiteral
            });
            setShowPrintModal(true);
        } catch (error) {
            Swal.fire('Error', 'No se pudo obtener el recibo', 'error');
        }
    };

    

    const sortedValoradoVens = useMemo(() => {
        let sortableVens = [...filteredValoradoVens];
        if (sortConfig !== null) {
            sortableVens.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableVens;
    }, [filteredValoradoVens, sortConfig]);

    const handleExport = () => {
        const worksheet = XLSX.utils.json_to_sheet(buildExportData(sortedValoradoVens));
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "VentasValorados");
        XLSX.writeFile(workbook, "ventas_valorados.xlsx");
    };

    const buildExportData = (data) => {
        return data.map(valoradoVen => ({
            Fecha: formaFechaI(valoradoVen.fecha),
            "Descripción Valorado": getValoradoDescripcion(valoradoVen.id_vd),
            Cantidad: valoradoVen.cantidad,
            PU: formatMonetaryValue(valoradoVen.pu),
            Monto: formatMonetaryValue(valoradoVen.monto),
            Descuento: formatMonetaryValue(valoradoVen.descuento),
            "Monto Pagado": formatMonetaryValue(valoradoVen.montopagado)
        }));
    };

    const handleShowPreview = () => {
        setPrintContent(getPreviewContent());
        setShowPreviewModal(true);
    };

    const getPreviewContent = () => {
        const content = {
            head: [['Fecha', 'Descripción Valorado', 'Cantidad', 'PU', 'Monto', 'Descuento', 'Monto Pagado']],
            body: sortedValoradoVens.map(valoradoVen => [
                formaFechaI(valoradoVen.fecha),
                getValoradoDescripcion(valoradoVen.id_vd),
                valoradoVen.cantidad,
                formatMonetaryValue(valoradoVen.pu),
                formatMonetaryValue(valoradoVen.monto),
                formatMonetaryValue(valoradoVen.descuento),
                formatMonetaryValue(valoradoVen.montopagado)
            ])
        };
        return content;
    };

    return (
        <div className="list-container list0">
            <h1 className='table-title'>Lista de Ventas de Valorados</h1>
            <div className="list-content">
                <div className='list-controls'>
                <ActionButton
                    onClick={() => {
                        if (!idCajaDiaria) {
                            alert('Debe estar abierta una caja para realizar esta acción.');
                            return;
                        }
                        handleCreate();
                    }}
                    outline
                    icon='plus'
                    disabled={!idCajaDiaria}
                >
                    Nueva Venta de Valorado
                </ActionButton>
                </div>
                <div className="list-container-header">
                    <div className="filters">
                        <input
                            type="text"
                            placeholder="Buscar por descripción"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                        <input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </div>
                    <div className="left-buttons">
                        <button title="Volver" onClick={() => navigate('/admin')} className="icon-button btn-transparent">
                                <i className="bi bi-arrow-left"></i>
                        </button>
                        <button title="Imprimir" onClick={handleShowPreview} className="icon-button btn-transparent">
                            <i className="bi bi-printer"></i>
                        </button>
                        <button title="Exportar a excel" onClick={handleExport} className="icon-button btn-transparent">
                            <i className="bi bi-file-spreadsheet"></i>
                        </button>
                    </div>
                </div>
                {sortedValoradoVens.length === 0 ? (
                    <p>No hay ventas de valorados disponibles.</p>
                ) : (
                    <div className='table-container'>
                        <Table bordered hover size='sm'>  
                            <thead style={{ maxHeight: '1000px', backgroundColor: "#b2aea3" }}>
                                <tr>
                                    <th onClick={() => requestSort('fecha')}>
                                        Fecha {sortConfig.key === 'fecha' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                                    </th>
                                    <th onClick={() => requestSort('id_vd')}>
                                        Descripción Valorado {sortConfig.key === 'id_vd' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                                    </th>
                                    <th>Cantidad</th>
                                    <th>PU</th>
                                    <th>Monto</th>
                                    <th>Descuento</th>
                                    <th>Monto Pagado</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedValoradoVens.map((valoradoVen) => (
                                    <tr key={valoradoVen.id}>
                                        <td>{formaFechaI(valoradoVen.fecha)}</td>
                                        <td>{getValoradoDescripcion(valoradoVen.id_vd)}</td>
                                        <td>{valoradoVen.cantidad}</td>
                                        <td>{formatMonetaryValue(valoradoVen.pu)}</td>
                                        <td>{formatMonetaryValue(valoradoVen.monto)}</td>
                                        <td>{formatMonetaryValue(valoradoVen.descuento)}</td>
                                        <td>{formatMonetaryValue(valoradoVen.montopagado)}</td>
                                        <td>
                                            <button className="icon-button btn-secondary-outline" title="Ver Detalle" onClick={() => handleViewDetail(valoradoVen)}>
                                                <i className="bi bi-search"></i>
                                            </button>
                                            <button className="icon-button btn-secondary-outline" title="Ver Recibo" onClick={() => handleViewRecibo(valoradoVen.id_recibo)}>
                                                <i className="bi bi-receipt"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                )}

                {/* Modal para ver los detalles */}
                <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Detalle de la Venta de Valorado</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {currentValoradoVen && (
                            <div>
                                <p><strong>Fecha:</strong> {formaFechaI(currentValoradoVen.fecha)}</p>
                                <p><strong>Descripción Valorado:</strong> {getValoradoDescripcion(currentValoradoVen.id_vd)}</p>
                                <p><strong>Cantidad:</strong> {currentValoradoVen.cantidad}</p>
                                <p><strong>PU:</strong> {formatMonetaryValue(currentValoradoVen.pu)}</p>
                                <p><strong>Monto:</strong> {formatMonetaryValue(currentValoradoVen.monto)}</p>
                                <p><strong>Descuento:</strong> {formatMonetaryValue(currentValoradoVen.descuento)}</p>
                                <p><strong>Monto Pagado:</strong> {formatMonetaryValue(currentValoradoVen.montopagado)}</p>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>Regresar</Button>
                    </Modal.Footer>
                </Modal>

                {/* Modal para crear una nueva venta de valorado */}
                <Modal show={showFormModal} onHide={() => setShowFormModal(false)} size="xl" backdrop="static" keyboard={false} dialogClassName="custom-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Nueva Venta de Valorado</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ValoradoVenForm handleClose={handleCloseFormModal} />
                    </Modal.Body>
                </Modal>

                {/* Modal para imprimir el recibo */}
                {reciboData && (
                    <ImprimirRecibo
                        show={showPrintModal}
                        handleClose={() => setShowPrintModal(false)}
                        recibo={reciboData.recibo}
                        reciboDetalles={reciboData.reciboDetalles}
                        totalLiteral={reciboData.totalLiteral}
                    />
                )}

                {/* Modal de vista previa para imprimir */}
                <CustomPrintModal2
                    show={showPreviewModal}
                    handleClose={() => setShowPreviewModal(false)}
                    title1="LISTA DE VENTAS DE VALORADOS"
                    title2={`Del ${startDate} al ${endDate}`}
                    content={printContent}
                    copia={false}
                />
            </div>
        </div>
    );
};

export default ValoradoVenList;
