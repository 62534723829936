import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTitulosProfesionalesByAfiliado, removeTituloProfesional } from '../../reducers/tituloProfesional_slice';
import TituloProfesionalForm from './TituloProfesionalForm';
import Table from 'react-bootstrap/Table';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CustomPrintModal from '../UI/CustomPrintModal';

const TituloProfesionalList = ({ afiliado, showModal, handleClose }) => {
  const dispatch = useDispatch();
  const titulos = useSelector(state => state.tituloProfesional.titulos || []);
  const [currentTitulo, setCurrentTitulo] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });

  useEffect(() => {
    console.log("Entro a titulos en List...")
    if (afiliado?.id) {
      dispatch(fetchTitulosProfesionalesByAfiliado(afiliado.id));
    }
  }, [dispatch, afiliado]);

  const handleDelete = id => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: '¡No podrás deshacer esta acción!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#464646',
      confirmButtonText: 'Sí, eliminarlo!',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(removeTituloProfesional(id)).then(() => {
          Swal.fire('¡Eliminado!', 'El título profesional ha sido eliminado.', 'success');
          dispatch(fetchTitulosProfesionalesByAfiliado(afiliado.id));
        });
      }
    });
  };

  const handleEdit = titulo => {
    setCurrentTitulo(titulo);
    setShowForm(true);
  };

  const handleCreate = () => {
    setCurrentTitulo(null);
    setShowForm(true);
  };

  const handleExportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      titulos.map(titulo => ({
        ID: titulo.id,
        Nombre: titulo.nombre_titulo,
        Grado: titulo.grado?.nombre || 'Sin grado',
        Institución: titulo.institucion?.nombre || 'Sin institución',
        Fecha:titulo.fecha_obtencion,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "TítulosProfesionales");
    XLSX.writeFile(workbook, `Titulos_Profesionales_${afiliado?.nombre || 'Afiliado'}.xlsx`);
  };

  const handleShowPreview = () => {
    const content = {
      head: [['Código', 'Nombre', 'Grado', 'Institución','fecha']],
      body: titulos.map(titulo => [
        titulo.id,
        titulo.nombre_titulo,
        titulo.grado?.nombre || 'Sin grado',
        titulo.institucion?.nombre || 'Sin institución',
        titulo.fecha_obtencion
      ])
    };
    setPrintContent(content);
    setShowPreviewModal(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
    if (afiliado?.id) {
      dispatch(fetchTitulosProfesionalesByAfiliado(afiliado.id));
    }
  };

  return (
    <Modal show={showModal} onHide={handleClose} centered size="xl" fullscreen="lg-down">
      <Modal.Header closeButton>
        <Modal.Title>Títulos Profesionales de {afiliado?.persona.nombre+" "+afiliado?.persona.apellido1}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="list-container-header d-flex justify-content-between mb-3">
          <div>
            <Button variant="outline-secondary" onClick={handleClose}>
              <i className="bi bi-arrow-left-circle"></i> Volver
            </Button>
          </div>
          <div>
            <Button variant="outline-primary" onClick={handleCreate} className="me-2">
              <i className="bi bi-plus-circle"></i> Nuevo Título Profesional
            </Button>
            <Button variant="outline-success" onDoubleClick={handleExportExcel} className="me-2">
              <i className="bi bi-file-earmark-excel"></i> Exportar Excel
            </Button>
            <Button variant="outline-secondary" onClick={handleShowPreview}>
              <i className="bi bi-printer"></i> Imprimir
            </Button>
          </div>
        </div>
        <div className="table-container">
          <Table bordered hover size="sm">
            <thead className="text-center align-middle">
              <tr>
                <th>Código</th>
                <th>Nombre</th>
                <th>Grado</th>
                <th>Institución</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {titulos.map(titulo => (
                <tr key={titulo.id}>
                  <td>{titulo.id}</td>
                  <td>{titulo.nombre_titulo}</td>
                  <td>{titulo.grado?.nombre || 'Sin grado'}</td>
                  <td>{titulo.institucion?.nombre || 'Sin institución'}</td>
                  <td className="text-center">
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      title="Editar"
                      onClick={() => handleEdit(titulo)}
                    >
                      <i className="bi bi-pencil"></i>
                    </Button>{' '}
                    <Button
                      variant="outline-danger"
                      size="sm"
                      title="Eliminar"
                      onClick={() => handleDelete(titulo.id)}
                    >
                      <i className="bi bi-trash"></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {titulos.length === 0 && (
            <p className="text-center mt-4">No hay títulos profesionales disponibles.</p>
          )}
        </div>
      </Modal.Body>
      {showForm && (
        <TituloProfesionalForm
          afiliado={afiliado}
          currentTitulo={currentTitulo}
          showModal={showForm}
          handleClose={handleCloseForm}
        />
      )}
      <CustomPrintModal
        show={showPreviewModal}
        handleClose={() => setShowPreviewModal(false)}
        title1={`TÍTULOS PROFESIONALES DE ${afiliado?.persona.nombre+" "+afiliado?.persona.apellido1}`}
        title2=""
        content={printContent}
        copia={false}
      />
    </Modal>
  );
};

export default TituloProfesionalList;
