import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAfiliados } from '../../reducers/afiliado_slice';
import Table from 'react-bootstrap/Table';
import CustomPrintModal from '../UI/CustomPrintModal1';
import * as XLSX from 'xlsx';
import Select from 'react-select';
import { fetchSituaciones } from '../../reducers/situacion_slice';

const AfiliadoReport = () => {
  const dispatch = useDispatch();
  const afiliados = useSelector(state => state.afiliado.afiliados || []);
  const situaciones = useSelector(state => state.situacion.situaciones || []);

  const [selectedSituaciones, setSelectedSituaciones] = useState([]);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [], title2: '', title3: '' });
  const [sortConfig, setSortConfig] = useState({ key: 'num_reg', direction: 'asc' });
  const [includeSignatureLines, setIncludeSignatureLines] = useState(false);
  const [customTitle, setCustomTitle] = useState('');
  const [cumpleañeros, setCumpleañeros] = useState(false); // Estado para el checkbox "Cumpleañeros"

  useEffect(() => {
    dispatch(fetchAfiliados());
    dispatch(fetchSituaciones());
  }, [dispatch]);

  const handleFilterSituacionChange = selected => {
    setSelectedSituaciones(selected || []);
  };

  const filteredAfiliados = useMemo(() => {
    let result = afiliados;
  
    if (selectedSituaciones.length > 0) {
      result = result.filter(afiliado =>
        selectedSituaciones.some(situ => situ.value === afiliado.id_situacion)
      );
    }
  
    if (cumpleañeros) {
      const today = new Date();
      const todayMonth = today.getMonth() + 1; // Mes actual (base 1)
      const todayDay = today.getDate(); // Día actual
      setCustomTitle("CUMPLEAÑEROS DEL DIA")
      result = result.filter(afiliado => {
        const fechaNacStr = afiliado.persona.fecha_nac;
        if (!fechaNacStr) return false; // Excluir si no hay fecha
  
        const [year, month, day] = fechaNacStr.split('-'); // Dividir por "-" para obtener partes
        if (!year || !month || !day) return false; // Excluir si el formato es incorrecto
  
        // Comparar el mes y el día con los valores actuales
        return parseInt(month, 10) === todayMonth && parseInt(day, 10) === todayDay;
      });
    }
  
    return result;
  }, [afiliados, selectedSituaciones, cumpleañeros]);
  

  const sortedAfiliados = useMemo(() => {
    const sorted = [...filteredAfiliados];
    sorted.sort((a, b) => {
      let aValue, bValue;
      if (sortConfig.key === 'num_reg') {
        aValue = parseInt(a.num_reg, 10);
        bValue = parseInt(b.num_reg, 10);
      } else {
        aValue = `${a.persona.apellido1} ${a.persona.apellido2} ${a.persona.nombre}`.toLowerCase();
        bValue = `${b.persona.apellido1} ${b.persona.apellido2} ${b.persona.nombre}`.toLowerCase();
      }
      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
    return sorted;
  }, [filteredAfiliados, sortConfig]);

  const handleSort = key => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleExport = () => {
    const exportData = sortedAfiliados.map((afiliado, index) => {
      const baseData = {
        'N°': index + 1,
        'C.I.': afiliado.persona.ci,
        'Situación': afiliado.situacion.nombre || 'N/A',
      };

      if (sortConfig.key === 'num_reg') {
        return {
          ...baseData,
          'N° Registro': afiliado.num_reg,
          'Nombre Completo': `${afiliado.persona.apellido1} ${afiliado.persona.apellido2} ${afiliado.persona.nombre}`,
        };
      } else {
        return {
          ...baseData,
          'Nombre Completo': `${afiliado.persona.apellido1} ${afiliado.persona.apellido2} ${afiliado.persona.nombre}`,
          'N° Registro': afiliado.num_reg,
        };
      }
    });

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Afiliados');
    XLSX.writeFile(workbook, 'afiliados.xlsx');
  };

  const getPreviewContent = () => {
    const headRow = ['N°', 'C.I.', 'Situación'];

    if (sortConfig.key === 'num_reg') {
      headRow.splice(1, 0, 'N° Registro', 'Nombre Completo');
    } else {
      headRow.splice(1, 0, 'Nombre Completo', 'N° Registro');
    }

    if (includeSignatureLines) {
      headRow.push('Firma'); // Agregar columna para firma
    }

    const bodyContent = sortedAfiliados.map((afiliado, index) => {
      const baseRow = [
        index + 1,
        afiliado.persona.ci,
        afiliado.situacion.nombre || 'N/A',
      ];

      if (sortConfig.key === 'num_reg') {
        baseRow.splice(1, 0, afiliado.num_reg, `${afiliado.persona.apellido1} ${afiliado.persona.apellido2} ${afiliado.persona.nombre}`);
      } else {
        baseRow.splice(1, 0, `${afiliado.persona.apellido1} ${afiliado.persona.apellido2} ${afiliado.persona.nombre}`, afiliado.num_reg);
      }

      if (includeSignatureLines) {
        baseRow.push('_______________________'); // Espacio reservado para firma
      }

      return baseRow;
    });

    return {
      head: [headRow],
      body: bodyContent,
      title2: customTitle || 'SIN TÍTULO',
      title3: new Date().toLocaleDateString(),
    };
  };

  const situacionOptions = situaciones.map(situacion => ({
    value: situacion.id,
    label: situacion.nombre,
  }));

  return (
    <div className="list-container list1">
      <h1 className="table-title">REPORTE DE AFILIADOS</h1>
      <div className="list-content">
        <div className="list-controls d-flex align-items-center">
          <div className="me-2">
            <Select
              isMulti
              options={situacionOptions}
              value={selectedSituaciones}
              onChange={handleFilterSituacionChange}
              placeholder="Filtrar por situación..."
              className="react-select-container"
              classNamePrefix="react-select"
            />
          </div>
          <div className="me-2 d-flex align-items-center">
            <input
              type="checkbox"
              id="cumpleañeros"
              checked={cumpleañeros}
              onChange={(e) => setCumpleañeros(e.target.checked)}
              className="form-check-input me-1"
            />
            <label htmlFor="cumpleañeros" className="form-check-label">
              Cumpleañeros
            </label>
          </div>
          <div className="flex-grow-1 me-2">
            <input
              type="text"
              placeholder="Título del reporte"
              className="form-control"
              value={customTitle}
              onChange={(e) => setCustomTitle(e.target.value)}
            />
          </div>
        </div>
        <div className="list-container-header d-flex justify-content-end">
          <button
            title="Incluir líneas de firma"
            onClick={() => setIncludeSignatureLines(!includeSignatureLines)}
            className="icon-button btn-transparent"
          >
            <i className={`bi ${includeSignatureLines ? 'bi-eye-slash' : 'bi-eye'}`}></i>
          </button>
          <button
            title="Imprimir"
            onClick={() => setShowPreviewModal(true)}
            className="icon-button btn-transparent"
          >
            <i className="bi bi-printer"></i>
          </button>
          <button
            title="Exportar a Excel"
            onDoubleClick={handleExport}
            className="icon-button btn-transparent"
          >
            <i className="bi bi-file-spreadsheet"></i>
          </button>
        </div>
        {sortedAfiliados.length === 0 ? (
          <p>No hay afiliados disponibles.</p>
        ) : (
          <div className="table-container">
            <Table bordered hover size="sm">
              <thead style={{ backgroundColor: '#b2aea3' }}>
                <tr>
                  <th>N°</th>
                  {sortConfig.key === 'num_reg' ? (
                    <>
                      <th onClick={() => handleSort('num_reg')} style={{ cursor: 'pointer' }}>
                        N° Registro {sortConfig.direction === 'asc' ? '↑' : '↓'}
                      </th>
                      <th onClick={() => handleSort('nombre_completo')} style={{ cursor: 'pointer' }}>
                        Nombre Completo
                      </th>
                    </>
                  ) : (
                    <>
                      <th onClick={() => handleSort('nombre_completo')} style={{ cursor: 'pointer' }}>
                        Nombre Completo {sortConfig.direction === 'asc' ? '↑' : '↓'}
                      </th>
                      <th onClick={() => handleSort('num_reg')} style={{ cursor: 'pointer' }}>
                        N° Registro
                      </th>
                    </>
                  )}
                  <th>C.I.</th>
                  <th>Situación</th>
                </tr>
              </thead>
              <tbody>
                {sortedAfiliados.map((afiliado, index) => (
                  <tr key={afiliado.id}>
                    <td>{index + 1}</td>
                    {sortConfig.key === 'num_reg' ? (
                      <>
                        <td>{afiliado.num_reg}</td>
                        <td>{`${afiliado.persona.apellido1} ${afiliado.persona.apellido2} ${afiliado.persona.nombre}`}</td>
                      </>
                    ) : (
                      <>
                        <td>{`${afiliado.persona.apellido1} ${afiliado.persona.apellido2} ${afiliado.persona.nombre}`}</td>
                        <td>{afiliado.num_reg}</td>
                      </>
                    )}
                    <td>{afiliado.persona.ci}</td>
                    <td>{afiliado.situacion.nombre || 'N/A'}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        <CustomPrintModal
          show={showPreviewModal}
          handleClose={() => setShowPreviewModal(false)}
          title1="LISTA DE AFILIADOS"
          title2={customTitle}
          title3={`Fecha: ${new Date().toLocaleDateString()}`}
          content={getPreviewContent()}
        />
      </div>
    </div>
  );
};

export default AfiliadoReport;
