import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCuotasByAfiliado } from '../../reducers/cuota_slice';
import { fetchAfiliadoByIdPersona } from '../../reducers/afiliado_slice';
import { formatFecha, numeroALetras } from '../../util/valueCalculator';
import ImprimirRecibo from '../recibo/ImprimirRecibo';
import * as XLSX from 'xlsx';
import CustomPrintModal from '../UI/CustomPrintModal3';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { useNavigate } from 'react-router-dom';
import { fetchLugares } from '../../reducers/lugar_slice';
import { fetchSituaciones } from '../../reducers/situacion_slice';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { fetchRecibos, fetchReciboById } from '../../reducers/recibo_slice';
import Dropdown from 'react-bootstrap/Dropdown';
import Swal from 'sweetalert2';

const AfiliadoEstadoCuenta = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const afiliado = useSelector(state => state.afiliado.current);
    const cuotas = useSelector(state => state.cuota.cuotas || []);
    const { id_persona } = useSelector((state) => state.sesion);
    const lugares = useSelector((state) => state.lugar.lugares || []);
    const situaciones = useSelector((state) => state.situacion.situaciones || []);

    const [startDate, setStartDate] = useState(`${new Date().getFullYear()}-01-01`);
    const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
    const [filterStatus, setFilterStatus] = useState('no_pagada');
    const [filteredCuotas, setFilteredCuotas] = useState([]);
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [printContent, setPrintContent] = useState({ head: [], body: [] });
    const [reciboData, setReciboData] = useState(null);
    const [showReciboModal, setShowReciboModal] = useState(false);

    useEffect(() => {
        if (id_persona) {
            dispatch(fetchAfiliadoByIdPersona(id_persona));
        }
        dispatch(fetchLugares());
        dispatch(fetchSituaciones());
    }, [dispatch, id_persona]);

    useEffect(() => {
        if (afiliado?.id) {
            console.log("Entro a despachar las cuotas del usuario=", afiliado.id);
            dispatch(fetchCuotasByAfiliado(afiliado.id));
        }
    }, [dispatch, afiliado?.id]);

    useMemo(() => {
        console.log("cuotas=", cuotas);
        const filtered = cuotas.filter(cuota => {
            const isInDateRange = (!startDate || new Date(cuota.fecha_emision) >= new Date(startDate)) &&
                (!endDate || new Date(cuota.fecha_emision) <= new Date(endDate));
            const matchesStatus = filterStatus === 'todas' || cuota.estado === filterStatus;
            return cuota.id_afiliado === afiliado?.id && isInDateRange && matchesStatus;
        });
        setFilteredCuotas(filtered);
    }, [cuotas, startDate, endDate, filterStatus, afiliado?.id]);

    const handleExportExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(
            filteredCuotas.map(cuota => ({
                Fecha: cuota.fecha_pago ? formatFecha(cuota.fecha_pago) : '',
                Cuota: cuota.cuota,
                Estado: cuota.estado,
                CuotaCat:cuota.monto_cat,
                DescCat: cuota.decuento_cat,
                PagadoCat:cuota.monto_cat_pagada,
                CuotaCab:cuota.monto_cab,
                DescCab:cuota.decuento_cab,
                PagadoCab:cuota.monto_cab_pagada
            }))
        );
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Cuotas");
        XLSX.writeFile(workbook, `cuotas_afiliado_${afiliado?.id}.xlsx`);
    };

    const handlePrint = () => {
        const content = {
            head: [['Fecha', 'Cuota', 'Estado', 'CuotaCat', 'DescCat', 'PagadoCat', 'CuotaCab', 'DescCab', 'PagadoCab']],
            body: filteredCuotas.map(cuota => [
                cuota.fecha_pago ? formatFecha(cuota.fecha_pago) : '',
                cuota.cuota,
                cuota.estado,
                cuota.monto_cat,
                cuota.decuento_cat,
                cuota.monto_cat_pagada,
                cuota.monto_cab,
                cuota.decuento_cab,
                cuota.monto_cab_pagada
            ])
        };
        setPrintContent(content);
        setShowPrintModal(true);
    };
    const handleBack = () => {
        navigate('/admin');
    };
    

    const handleViewRecibo = async (id_recibo) => {
        try {
            const recibo = await dispatch(fetchReciboById(id_recibo)).unwrap();
            const totalLiteral = numeroALetras(recibo.monto);

            setReciboData({
                recibo,
                reciboDetalles: recibo.reciboDets,
                totalLiteral
            });
            setShowReciboModal(true);
        } catch (error) {
            Swal.fire('Error', 'No se pudo obtener el recibo', 'error');
        }
    };




    return (
        <div className="list-container list0">
            <h1 className='table-title'>Estado de Cuenta</h1>

            <section className="afiliado-info">
                {afiliado && (
                    <Row>
                        <Col md={6}>
                            <p>
                                <strong>Apellidos y Nombre:</strong> {`${afiliado?.persona?.apellido1 || ''} ${afiliado?.persona?.apellido2 || ''}, ${afiliado?.persona?.nombre || ''}`}
                            </p>
                        </Col>
                        <Col md={4}>
                            <p>
                                <strong>  C.I.:</strong> {`${afiliado?.persona?.ci || ''} `}
                            </p>
                        </Col>

                        <Col md={4}>
                            <p>
                                <strong>Número de Registro:</strong> {afiliado?.num_reg || 'No disponible'}
                            </p>
                        </Col>
                        <Col md={4}>
                            <p>
                                <strong>Fecha de Registro:</strong> {afiliado?.fecha_reg_cab || 'No disponible'}
                            </p>
                        </Col>
                        <Col md={4}>
                            <p>
                                <strong>Situación:</strong> {situaciones.find((s) => s.id === afiliado?.id_situacion)?.nombre || 'No disponible'}
                            </p>
                        </Col>
                    </Row>
                )}
            </section>

            <section className="filtros">
                <h4>Filtros</h4>
                <div className="d-flex align-items-center justify-content-between filter-controls">
                    <div className="d-flex gap-3">
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                        <input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                        <select
                            value={filterStatus}
                            onChange={(e) => setFilterStatus(e.target.value)}
                        >
                            <option value="no_pagada">No Pagadas</option>
                            <option value="pagada">Pagadas</option>
                            <option value="todas">Todas</option>
                        </select>
                    </div>
                    <div className="right-buttons d-flex gap-2">
                        <button title="Volver" onClick={handleBack} className="icon-button btn-transparent">
                            <i className="bi bi-arrow-left"></i>
                        </button>
                        <button onClick={handlePrint} className="icon-button btn-transparent">
                            <i className="bi bi-printer"></i> Imprimir
                        </button>
                        <button onDoubleClick={handleExportExcel} className="icon-button btn-transparent">
                            <i className="bi bi-file-spreadsheet"></i> Exportar a Excel
                        </button>
                    </div>
                </div>
            </section>

            <section className="cuotas-list">
                <h4>Cuotas</h4>
                <div className="table-responsive">
                    <Table bordered hover size='sm'>
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Cuota</th>
                                <th>Estado</th>
                                <th>MontoCat</th>
                                <th>DescCat</th>
                                <th>PagadoCat</th>
                                <th>MontoCab</th>
                                <th>DescCab</th>
                                <th>PagadoCab</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredCuotas.map(cuota => (
                                <tr key={cuota.id}>
                                    <td>{cuota.fecha_emision ? formatFecha(cuota.fecha_emision) : ''}</td>
                                    <td>{cuota.cuota}</td>
                                    <td>{cuota.estado}</td>
                                    <td>{cuota.monto_cat}</td>
                                    <td>{cuota.decuento_cat}</td>
                                    <td>{cuota.monto_cat_pagada}</td>
                                    <td>{cuota.monto_cab}</td>
                                    <td>{cuota.decuento_cab}</td>
                                    <td>{cuota.monto_cab_pagada}</td>
                                    <td>
                                        {cuota.estado === 'pagada' && (
                                            <button
                                                className="icon-button btn-secondary-outline"
                                                title="Imprimir Recibo"
                                                onClick={() => handleViewRecibo(cuota.id_recibo)}
                                            >
                                                <i className="bi bi-receipt"></i>
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </section>

            {reciboData && (
                <ImprimirRecibo
                    show={showReciboModal}
                    handleClose={() => setShowReciboModal(false)}
                    recibo={reciboData.recibo}
                    reciboDetalles={reciboData.reciboDetalles}
                    totalLiteral={reciboData.totalLiteral}
                />
            )}

            <CustomPrintModal
                show={showPrintModal}
                handleClose={() => setShowPrintModal(false)}
                title1="ESTADO DE CUOTAS"
                title2={`AFILIADO: ${afiliado?.persona?.apellido1} ${afiliado?.persona?.apellido2}, ${afiliado?.persona?.nombre}`}
                title3={`NRO. REG.: ${afiliado?.num_reg}  C.I.: ${afiliado?.persona?.ci}`}
                title4={`SITUACIÓN: ${situaciones.find((s) => s.id === afiliado?.id_situacion)?.nombre || 'No disponible'}`}
                title5={`RANGO DE FECHAS: ${startDate} a ${endDate}`}
                content={printContent}
            />
        </div>
    );
};

export default AfiliadoEstadoCuenta;
