import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService";

const valoradoVenService = apiService('/valorado-vens');

// Obtener todos los valorado_vens
export const fetchValoradoVens = createAsyncThunk('valoradoVen/fetchValoradoVens', async () => {
    const response = await valoradoVenService.getAll();
    return response || [];
});

// Crear un nuevo valorado_ven
export const addValoradoVen = createAsyncThunk('valoradoVen/addValoradoVen', async (valoradoVen, { rejectWithValue }) => {
    try {
        const response = await valoradoVenService.create(valoradoVen);
        return response;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

// Obtener el número de certificado
export const fetchNumeroCertificado = createAsyncThunk(
    'valoradoVen/fetchNumeroCertificado',
    async ({ id_afiliado, nombre_certificado }, { rejectWithValue }) => {
        try {
            const response = await valoradoVenService.createSome(
                '/obtener-certificado-numero',
                {
                    id_afiliado,
                    nombre_certificado,
                }
            );
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Actualizar un valorado_ven (modificar el campo usado)
export const updateValoradoVen = createAsyncThunk(
    'valoradoVen/updateValoradoVen',
    async ({ id, usado }, { rejectWithValue }) => {
        try {
            const response = await valoradoVenService.update(id, { usado });
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const initialState = {
    valoradoVens: [],
    numeroCertificado: null,
    statusFetchNumero: 'idle',
    statusUpdate: 'idle', // Estado para la actualización
    error: null,
};

const valoradoVenSlice = createSlice({
    name: 'valoradoVen',
    initialState,
    reducers: {
        clearNumeroCertificado(state) {
            state.numeroCertificado = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchValoradoVens.fulfilled, (state, action) => {
                state.valoradoVens = action.payload;
            })
            .addCase(addValoradoVen.fulfilled, (state, action) => {
                state.valoradoVens.push(action.payload);
            })
            .addCase(fetchNumeroCertificado.pending, (state) => {
                state.statusFetchNumero = 'loading';
                state.numeroCertificado = null;
                state.error = null;
            })
            .addCase(fetchNumeroCertificado.fulfilled, (state, action) => {
                state.statusFetchNumero = 'succeeded';
                state.numeroCertificado = action.payload;
            })
            .addCase(fetchNumeroCertificado.rejected, (state, action) => {
                state.statusFetchNumero = 'failed';
                state.error = action.payload;
            })
            .addCase(updateValoradoVen.pending, (state) => {
                state.statusUpdate = 'loading';
                state.error = null;
            })
            .addCase(updateValoradoVen.fulfilled, (state, action) => {
                state.statusUpdate = 'succeeded';
                // Actualizar el valor en el estado
                const index = state.valoradoVens.findIndex(v => v.id === action.payload.id);
                if (index !== -1) {
                    state.valoradoVens[index] = {
                        ...state.valoradoVens[index],
                        ...action.payload,
                    };
                }
            })
            .addCase(updateValoradoVen.rejected, (state, action) => {
                state.statusUpdate = 'failed';
                state.error = action.payload;
            });
    }
});

export const { clearNumeroCertificado } = valoradoVenSlice.actions;
export default valoradoVenSlice.reducer;
