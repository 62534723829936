import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import TablePersona from './TablePersona';

const initialState = {
    id_persona:"",
    personaSeleccionada:null 
}
const SelectPersonas = ({
  onSelect,
  labelText,
  personasSelected = [],
  errorFeedback = {},
  excludeList = null,
  simplePersona = null,
  permitirEdicion = true,
  actualizarListaFn = null,
}) => {
  const [datos, setDatos] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (personasSelected.length > 0) {
      setDatos(personasSelected);
    } else {
      setDatos([]);
    }
  }, [personasSelected]);

  const handleAddPersona = (persona) => {
    if (permitirEdicion) {
      if (!datos.some((p) => p.id === persona.id)) {
        const nuevaLista = [...datos, persona];
        setDatos(nuevaLista);
        onSelect(nuevaLista);
        setShowModal(false); // Cierra el modal después de seleccionar
      }
    }
  };

  const handleRemovePersona = (personaId) => {
    if (permitirEdicion) {
      const nuevaLista = datos.filter((p) => p.id !== personaId);
      setDatos(nuevaLista);
      onSelect(nuevaLista);
    }
  };

  return (
    <>
      <Form.Label className="mt-1">{labelText}</Form.Label>
      {datos.length > 0 ? (
        <div>
          {datos.map((persona) => (
            <div key={persona.id}  className="selected-container p-3 border rounded shadow-sm">
           <div className="d-flex align-items-center justify-content-between">
              <div>
                <strong>
                  {`${persona.nombre} ${persona.apellido1} ${persona.apellido2}`}
                </strong>
                <br />
                <span>C.I.: {persona.ci}</span>
              </div>
              </div>
              {permitirEdicion && (
                <Button
                  variant="link"
                  onClick={() => handleRemovePersona(persona.id)}
                  className="btn-sm text-danger"
                >
                  Quitar
                </Button>
              )}
            </div>
          ))}
          {permitirEdicion && (
            <Button variant="link" onClick={() => setShowModal(true)}>
              Agregar persona
            </Button>
          )}
        </div>
      ) : (
        <div className="selected-container p-3 border rounded shadow-sm">
          <p>No se ha seleccionado ninguna persona aún.</p>
          <Button variant="link" onClick={() => setShowModal(true)}>
            Seleccionar persona
          </Button>
        </div>
      )}
      {errorFeedback.show && (
        <p className="text-danger mt-2">{errorFeedback.message}</p>
      )}

      {/* Modal para seleccionar persona */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Seleccionar personas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TablePersona
            onSelectPersona={handleAddPersona}
            simplePersona={simplePersona}
            excludeList={excludeList}
            actualizarListaFn={actualizarListaFn}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SelectPersonas;
