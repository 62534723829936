import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Table, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { fetchCuotasByAfiliado, modifyCuotaWithDeposito, fetchDepositoConCuotas } from '../../reducers/cuota_slice';
import { fetchBancos } from '../../reducers/banco_slice';
import moment from 'moment-timezone';
import { BsEye, BsEyeSlash, BsCamera } from 'react-icons/bs';
import EpsonPrintModal from '../UI/EpsonPrintModal'; 
import VistaPagoCuota from './VistaPagoCuota';

import { fetchDatGeneral } from "../../reducers/datGeneral_slice";


const PagoCuotasModal = ({ show, handleClose, afiliado, openVistaPagoCuotaModal }) => {
  const dispatch = useDispatch();
  const [selectedCuotas, setSelectedCuotas] = useState([]);
  const [depositos, setDepositos] = useState([{ id_banco: '', monto: '', fecha_deposito: moment().format('YYYY-MM-DD'), docum: '', archivo: null }]);
  const [totalDepositado, setTotalDepositado] = useState(0);
  const [imagenPreview, setImagenPreview] = useState(null);
  const [imagenVisible, setImagenVisible] = useState([]);
  const [botonGrabarHabilitado, setBotonGrabarHabilitado] = useState(false);
  const [botonProformaHabilitado, setBotonProformaHabilitado] = useState(false);
  const [showEpsonPrintModal, setShowEpsonPrintModal] = useState(false); 
  const [proformaContent, setProformaContent] = useState(null); 
  const [paymentSaved, setPaymentSaved] = useState(false); 
  const [showVistaPagoCuota, setShowVistaPagoCuota] = useState(false); 
  const [depositoId, setDepositoId] = useState(null); 
  const [showCameraModal, setShowCameraModal] = useState(false); 
  const [nombreArchivo, setNombreArchivo] = useState(''); 
  const [depositIndex, setDepositIndex] = useState(null); // Nuevo estado para almacenar el índice de la fila actual
  const videoRef = useRef(null); 
  const datgeneralList = useSelector((state) => state.datgeneral.datgeneralList);
  const bancos = useSelector(state => state.banco.bancos || []);
  const cuotas = useSelector(state => state.cuota.cuotas.filter(cuota => cuota.estado === 'no_pagada'));
  const userId = useSelector(state => state.sesion.id);
  const xcaja=useSelector(state => state.sesion.caja);
  const xcuentaC=useSelector(state => state.sesion.cuentacaja);

  const [selectedDepositoId, setSelectedDepositoId] = useState(null);
  const [selectedReciboId, setSelectedReciboId] = useState(null);
  const xpago="B";
  const [cuotasEditable, setCuotasEditable] = useState([]);
  const xcuentaM=datgeneralList[0]?.cuenta_CAT||'';
  const xcuentaN=datgeneralList[0]?.cuenta_CAB||'';
  const xcuotas=datgeneralList[0]?.cuotas_tolerancia||0;
  const today = new Date().toLocaleDateString('es-BO', {
    timeZone: 'America/La_Paz',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }).split('/').reverse().join('-');
  //console.log("CAT y CAB",xcuentaM,xcuentaN)

  useEffect(() => {
    dispatch(fetchDatGeneral()); 
    if (afiliado && show) {
      dispatch(fetchCuotasByAfiliado(afiliado.id));
    }
    if (bancos.length === 0) {
      dispatch(fetchBancos());
    }
  }, [dispatch, afiliado, show, bancos.length]);

  useEffect(() => {
    const total = depositos.reduce((total, deposito) => total + parseFloat(deposito.monto || 0), 0);
    setTotalDepositado(total);
    validarCondicionesParaGrabar();
    validarCondicionesParaProforma();
  }, [depositos, selectedCuotas]);

  useEffect(() => {
    if (show && cuotas.length > 0 && afiliado && cuotasEditable.length === 0) {
      const cuotasFiltradas = cuotas.filter((cuota) => cuota.id_afiliado === afiliado.id);
      console.log('Cuotas filtradas:', cuotasFiltradas);
      setCuotasEditable(
        cuotasFiltradas.map((cuota) => ({
          ...cuota,
          descuento_cat: cuota.descuento_cat || 0,
          descuento_cab: cuota.descuento_cab || 0,
          observacion: cuota.observacion || '',
        }))
      );
    }
  }, [show, cuotas, afiliado]);
 

  const handleCuotaSelect = (index, checked) => {
    const newSelectedCuotas = [...selectedCuotas];
    if (checked) {
      for (let i = 0; i <= index; i++) {
        newSelectedCuotas[i] = true;
      }
    } else {
      for (let i = index; i < newSelectedCuotas.length; i++) {
        newSelectedCuotas[i] = false;
      }
    }
    setSelectedCuotas(newSelectedCuotas);
  };

  const handleAddDeposito = () => {
    const lastDeposito = depositos[depositos.length - 1];
    if (lastDeposito.id_banco && lastDeposito.monto && lastDeposito.fecha_deposito && lastDeposito.docum && lastDeposito.archivo) {
      setDepositos([...depositos, { id_banco: '', monto: '', fecha_deposito: moment().format('YYYY-MM-DD'), docum: '', archivo: null }]);
    } else {
      Swal.fire('Error', 'Debe completar todos los campos del depósito antes de agregar uno nuevo.', 'error');
    }
  };

  const handleRemoveDeposito = (index) => {
    const newDepositos = depositos.filter((_, i) => i !== index);
    setDepositos(newDepositos);
  };

  const handleFileChange = (index, file) => {
    const newDepositos = [...depositos];
    newDepositos[index].archivo = file;
    setDepositos(newDepositos);
  };

  const handleChange = (index, field, value) => {
    const newDepositos = [...depositos];
    if (field === 'fecha_deposito' && moment(value).isAfter(moment())) {
      Swal.fire('Error', 'La fecha de depósito no puede ser posterior a la fecha actual.', 'error');
      return;
    }
    newDepositos[index][field] = value;
    setDepositos(newDepositos);
  };

  const toggleImageVisibility = (index) => {
    const newVisibility = [...imagenVisible];
    newVisibility[index] = !newVisibility[index];
    setImagenVisible(newVisibility.map((visible, i) => (i === index ? visible : false)));

    if (newVisibility[index] && depositos[index].archivo && depositos[index].archivo.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e) => setImagenPreview(e.target.result);
      reader.readAsDataURL(depositos[index].archivo);
    } else {
      setImagenPreview(null);
    }
  };

  const validarCondicionesParaGrabar = () => {
   
    const cuotasSeleccionadas = cuotasEditable.filter((_, index) => selectedCuotas[index]);
    const totalCuotas = cuotasSeleccionadas.reduce(
      (total, cuota) =>
        total +
        parseFloat(cuota.monto_cat || 0)- parseFloat(cuota.descuento_cat || 0)+
        parseFloat(cuota.monto_cab || 0)- parseFloat(cuota.descuento_cab || 0)
        ,
      0
    );
  
    const observacionesValidas = cuotas.every((cuota) => {
      const tieneDescuento = (cuota.descuento_cat || cuota.descuento_cab) > 0;
      const tieneObservacionValida = cuota.observacion && cuota.observacion.trim().length > 0;
    
      // Si tiene descuento, debe tener observación válida
      if (tieneDescuento) {
        return tieneObservacionValida;
      }
    
      // Si no tiene descuento, no necesita observación
      return true;
    });
    const camposCompletos = depositos.every(
      (deposito) =>
        deposito.id_banco &&
        deposito.monto &&
        deposito.fecha_deposito &&
        deposito.docum &&
        deposito.archivo
    );
    //console.log('totalDepositaado=',totalDepositado,' TotalCUotas=',totalCuotas)
    const totalCorrecto = totalDepositado === totalCuotas;
    const hayCuotasSeleccionadas =
      cuotas.length > 0 && totalCuotas > 0;
    console.log("completos totales haycuotas obsercaiones paymen=",camposCompletos,totalCorrecto,hayCuotasSeleccionadas,observacionesValidas,paymentSaved)
    setBotonGrabarHabilitado(
      camposCompletos && totalCorrecto && hayCuotasSeleccionadas && observacionesValidas && !paymentSaved
    );
  };

  const validarCondicionesParaProforma = () => {
    const hayCuotasSeleccionadas = cuotasEditable.some((_, index) => selectedCuotas[index]);
    setBotonProformaHabilitado(hayCuotasSeleccionadas && !paymentSaved); 
  };

  const handleProforma = () => {
    const cuotasSeleccionadas = cuotasEditable.filter((_, index) => selectedCuotas[index]);
    const totalCat = cuotasSeleccionadas.reduce(
      (total, cuota) =>
        total +
        (parseFloat(cuota.monto_cat || 0) - parseFloat(cuota.descuento_cat || 0)),
      0
    );
    
    const totalCab = cuotasSeleccionadas.reduce(
      (total, cuota) =>
        total +
        (parseFloat(cuota.monto_cab || 0) - parseFloat(cuota.descuento_cab || 0)),
      0
    );
    const totalGeneral = totalCat + totalCab;

    const detalle = [
      'PROFORMA PAGO DE CUOTAS',
      '=======================',
      `Afiliado: ${afiliado.persona.nombre} ${afiliado.persona.apellido1} ${afiliado.persona.apellido2}`,
      `C.I.: ${afiliado.persona.ci}`,
      `Nro. Reg.: ${afiliado.num_reg}`,
      '--------------------------------------',
      `TOTAL CAT = ${totalCat.toFixed(2)} Bs.`,
      `TOTAL CAB = ${totalCab.toFixed(2)} Bs.`,
      '______________________________________',
      `TOTAL = ${totalGeneral.toFixed(2)} Bs.`
    ];

    setProformaContent({ detalle });
    setShowEpsonPrintModal(true); 
  };

  const handlePay = async () => {

    //console.log("datGeneal=",datgeneralList)
    const confirm = await Swal.fire({
        title: '¿Está seguro?',
        text: 'Este proceso es irreversible. ¿Desea continuar?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, grabar'
    });

    if (!confirm.isConfirmed) return;

 

    const cuotasToPay = cuotasEditable.filter((_, index) => selectedCuotas[index]);

    const totalCuotas = cuotasToPay.reduce(
      (total, cuota) =>
        total +
        (parseFloat(cuota.monto_cat || 0) - parseFloat(cuota.descuento_cat || 0)) +
        (parseFloat(cuota.monto_cab || 0) - parseFloat(cuota.descuento_cab || 0)),
      0
    );
    

    // Generar concepto dinámico
    let concepto;
    if (depositos.length > 0 && depositos.some(deposito => deposito.monto)) {
        concepto = "Pago cuotas " + depositos.map(deposito => {
          const banco = bancos.find(b => b.id === Number(deposito.id_banco)); // Convertimos id_banco a número
           // console.log("banco=",banco," deposito=",deposito)
            const siglaBanco = banco ? banco.sigla : 'Sin sigla';
            const cuentaBancaria = banco ? banco.cuenta_bancaria : 'Sin cuenta';
            return `${siglaBanco} (${cuentaBancaria}): Bs. ${parseFloat(deposito.monto).toFixed(2)} Trans.: ${deposito.docum}`;
        }).join(' | ');
    } else {
        concepto = 'Pago de cuotas en efectivo';
    }

    const recibo = {
      tipo: 'INGRESO',
      fecha: moment().tz("America/La_Paz").format('YYYY-MM-DD HH:mm:ss'),
      monto: totalCuotas,
      beneficiario: `${afiliado.persona.apellido1} ${afiliado.persona.apellido2}, ${afiliado.persona.nombre}  Nro.Reg.: ${afiliado.num_reg}`,
      ci_persona: afiliado.persona.ci,
      concepto, // Usar el concepto dinámico generado
      id_caja: xcaja,
      id_usuario: userId,
      pago: depositos.length > 0 && depositos.some(deposito => deposito.monto) ? "B" : "C" // "B" si hay depósitos, "C" si no
    };

    const reciboDetalles = cuotasToPay.flatMap(cuota => [
      {
          id_caja: xcaja,
          tipo: 'INGRESO',
          fecha: moment().tz("America/La_Paz").format('YYYY-MM-DD HH:mm:ss'),
          concepto: `Pago cuota ${cuota.cuota} CAT=${cuota.monto_cat}`,
          cantidad: 1,
          pu: parseFloat(cuota.monto_cat || 0),
          monto: parseFloat(cuota.monto_cat || 0),
          pagado: parseFloat(cuota.monto_cat || 0)-parseFloat(cuota.descuento_cat || 0),
          descuento: cuota.descuento_cat,
          id_usuario: userId,
          codigo_cuentaC: xcuentaC,
          codigo_cuentaM: xcuentaM, // Asociado a CAT
          pago: recibo.pago
      },
      {
          id_caja: xcaja,
          tipo: 'INGRESO',
          fecha: moment().tz("America/La_Paz").format('YYYY-MM-DD HH:mm:ss'),
          concepto: `Pago cuota ${cuota.cuota} CAB=${cuota.monto_cab}`,
          cantidad: 1,
          pu: parseFloat(cuota.monto_cab || 0),
          monto: parseFloat(cuota.monto_cab || 0),
          pagado: parseFloat(cuota.monto_cab ||0)-parseFloat(cuota.descuento_cab || 0),
          descuento: cuota.descuento_cab,
          id_usuario: userId,
          codigo_cuentaC: xcuentaC,
          codigo_cuentaM: xcuentaN, // Asociado a CAB
          pago: recibo.pago
      }
  ]);

    try {
        //console.log("CAT y CAB=",xcuentaM,totalCat,xcuentaN,totalCab)
        const response = await dispatch(modifyCuotaWithDeposito({
            id_afiliado: afiliado.id,
            cuotas: cuotasToPay.map(cuota => ({
                id: cuota.id,
                monto_cat_pagada: cuota.monto_cat - cuota.descuento_cat,
                monto_cab_pagada: cuota.monto_cab - cuota.descuento_cab,
                descuento_cat:cuota.descuento_cat,
                descuento_cab:cuota.descuento_cab,
                observacion:cuota.observacion,
                fecha_pago: moment().tz("America/La_Paz").format('YYYY-MM-DD HH:mm:ss'),
                estado: 'pagada',
                id_usuario: userId
            })),
            depositos, 
            recibo, 
            reciboDetalles,
            cuentaCAT:xcuentaM,
            montoCAT:totalCat,
            cuentaCAB:xcuentaN,
            montoCAB:totalCab,
        })).unwrap();

        if (response.message === 'Cuotas, depósitos y recibos registrados correctamente') {
            //console.log("Recibo=", response.id_recibo, " Deposito=", response.id_deposito);
            Swal.fire('Éxito', 'Pago registrado exitosamente', 'success');
            setSelectedDepositoId(response.id_deposito);
            setSelectedReciboId(response.id_recibo);
            //setShowVistaPagoCuota(true); // Abrir modal
            setPaymentSaved(true);
            handleClose({ idDeposito: response.id_deposito, idAfiliado:afiliado.id, idRecibo: response.id_recibo });
        } else {
          Swal.fire('Error', 'Ocurrió un problema', 'error');
      }
    } catch (error) {
        Swal.fire('Error', 'Error al procesar el pago', 'error');
    }
};

  const startCamera = (index) => {
    const iniciales = `${afiliado.persona.apellido1.charAt(0)}${afiliado.persona.apellido2.charAt(0)}${afiliado.persona.nombre.split(' ').map(n => n.charAt(0)).join('')}`;
    const randomNum = Math.floor(Math.random() * 1000) + 1;

    setNombreArchivo(`${iniciales}-${randomNum}.jpg`);
    setDepositIndex(index); // Guarda el índice actual
    setShowCameraModal(true);

    navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
      videoRef.current.srcObject = stream;
      videoRef.current.play();
    }).catch((err) => {
      console.error("Error al acceder a la cámara: ", err);
    });
  };

  const captureImage = () => {
    if (!nombreArchivo) {
      Swal.fire('Error', 'Debe proporcionar un nombre para el archivo antes de capturar la imagen.', 'error');
      return;
    }

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    const dataUrl = canvas.toDataURL('image/jpeg');
    const blob = dataURLtoBlob(dataUrl);

    const file = new File([blob], nombreArchivo, { type: 'image/jpeg' });

    if (depositIndex !== null) {
      handleFileChange(depositIndex, file); // Actualiza la fila correcta
    }

    setShowCameraModal(false);
  };

  const handleCuotaChange = (index, field, value) => {
    setCuotasEditable((prevState) =>
      prevState.map((cuota, i) => {
        if (i === index) {
          // Verifica si el campo es numérico
          if (field === 'descuento_cat' || field === 'descuento_cab') {
            const nuevoValor = parseFloat(value) || 0;
            if (
              (field === 'descuento_cat' && nuevoValor >= cuota.monto_cat) ||
              (field === 'descuento_cab' && nuevoValor >= cuota.monto_cab)
            ) {
              Swal.fire(
                'Descuento no válido',
                `El descuento no puede ser igual o mayor al monto de ${
                  field === 'descuento_cat' ? 'CAT' : 'CAB'
                }.`,
                'error'
              );
              return cuota; // No actualiza el estado si el descuento no es válido
            }
            return { ...cuota, [field]: nuevoValor };
          }
  
          // Para campos de texto como "observacion"
          return { ...cuota, [field]: value };
        }
        return cuota;
      })
    );
  };
  
  const dataURLtoBlob = (dataURL) => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  useEffect(() => {
    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        videoRef.current.srcObject.getTracks().forEach(track => track.stop());
      }
    };
  }, [showCameraModal]);

  const totalCat = cuotas.reduce(
    (total, cuota, index) =>
      selectedCuotas[index]
        ? total +
          parseFloat(cuota.monto_cat || 0) -
          parseFloat(cuota.descuento_cat || 0)
        : total,
    0
  );
  const totalCab = cuotas.reduce(
    (total, cuota, index) =>
      selectedCuotas[index]
        ? total +
          parseFloat(cuota.monto_cab || 0) -
          parseFloat(cuota.descuento_cab || 0)
        : total,
    0
  );

  const totalGeneral = totalCat + totalCab;

  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Pagar Cuotas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ position: 'relative' }}>
            {imagenPreview && (
              <img
                src={imagenPreview}
                alt="Imagen de Depósito"
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  maxWidth: '150px',
                  border: '1px solid #ccc',
                  padding: '5px',
                  borderRadius: '5px',
                  backgroundColor: '#fff'
                }}
              />
            )}
            {afiliado && (
              <div>
                <h5>Datos del Afiliado</h5>
                <p><strong>Nombre:</strong> {`${afiliado.persona.nombre} ${afiliado.persona.apellido1} ${afiliado.persona.apellido2}`}</p>
                <p><strong>C.I.:</strong> {afiliado.persona.ci}</p>
                <p><strong>N° de Registro:</strong> {afiliado.numero_registro}</p>

                <h5>Registrar Depósitos</h5>
                <Table bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>Banco</th>
                      <th>Monto</th>
                      <th>Fecha Depósito</th>
                      <th>Documento</th>
                      <th>Archivo</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {depositos.map((deposito, index) => (
                      <tr key={index}>
                        <td>
                          <Form.Control
                            as="select"
                            value={deposito.id_banco}
                            onChange={(e) => handleChange(index, 'id_banco', e.target.value)}
                            disabled={paymentSaved}
                          >
                            <option value="">Seleccione un banco</option>
                            {bancos.map(banco => (
                              <option key={banco.id} value={banco.id}>
                                {banco.banco}
                              </option>
                            ))}
                          </Form.Control>
                        </td>
                        <td>
                          <Form.Control
                            type="number"
                            value={deposito.monto}
                            onChange={(e) => handleChange(index, 'monto', e.target.value)}
                            required
                            disabled={paymentSaved}
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="date"
                            value={deposito.fecha_deposito}
                            onChange={(e) => handleChange(index, 'fecha_deposito', e.target.value)}
                            required
                            disabled={paymentSaved}
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="text"
                            value={deposito.docum}
                            onChange={(e) => handleChange(index, 'docum', e.target.value)}
                            disabled={paymentSaved}
                          />
                        </td>
                        <td style={{ display: 'flex', alignItems: 'center' }}>
                          <Form.Control
                            type="file"
                            onChange={(e) => handleFileChange(index, e.target.files[0])}
                            disabled={paymentSaved}
                          />
                          {deposito.archivo && (
                            <>
                              <span style={{ marginLeft: '10px' }}>{deposito.archivo.name}</span>
                              <button
                                className="icon-button"
                                style={{ marginLeft: '10px' }}
                                onClick={() => toggleImageVisibility(index)}
                               type="button"
                              >
                                {imagenVisible[index] ? <BsEyeSlash /> : <BsEye />}
                              </button>
                            </>
                          )}
                          <button
                            className="icon-button"
                            style={{ marginLeft: '10px' }}
                            onClick={() => startCamera(index)}
                          >
                            <BsCamera />
                          </button>
                        </td>
                        <td>
                          {!paymentSaved && index === depositos.length - 1 && (
                            <button className="icon-button" onClick={handleAddDeposito}>
                              <i className="bi bi-plus-circle"></i>
                            </button>
                          )}
                          {!paymentSaved && depositos.length > 1 && (
                            <button className="icon-button" onClick={() => handleRemoveDeposito(index)}>
                              <i className="bi bi-trash"></i>
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
      
                <p><strong>Total Depositado:</strong> <span style={{ fontWeight: 'bold' }}>{totalDepositado.toFixed(2)} Bs.</span></p>
              </div>
            )}
          </div>

          <h5>Cuotas No Pagadas</h5>
          <Table bordered hover size="sm">
  <thead style={{ backgroundColor: '#b2aea3' }}>
    <tr>
      <th>Paga</th>
      <th>Cuota</th>
      <th>Monto CAT</th>
      <th>Desc.CAT</th>
      <th>Pagado CAT</th>
      <th>Monto CAB</th>
      <th>Desc. CAB</th>
      <th>Pagado CAB</th>
      <th>Observación</th>
    </tr>
  </thead>
  <tbody>
  {cuotasEditable.length === 0 ? (
    <tr>
      <td colSpan="9">No hay cuotas pendientes</td>
    </tr>
  ) : (
    cuotasEditable.map((cuota, index) => (
      <tr key={cuota.id} style={{ fontWeight: selectedCuotas[index] ? 'bold' : 'normal' }}>
        <td>
          <Form.Check
            type="checkbox"
            checked={selectedCuotas[index] || false}
            onChange={(e) => handleCuotaSelect(index, e.target.checked)}
            disabled={paymentSaved}
          />
        </td>
        <td>{cuota.cuota}</td>
        <td>{parseFloat(cuota.monto_cat || 0).toFixed(2)} Bs.</td>
        <td>
          <Form.Control
            type="number"
            min="0"
            step="0.01"
            value={cuota.descuento_cat}
            onChange={(e) =>
              handleCuotaChange(index, 'descuento_cat', parseFloat(e.target.value) || 0)
            }
            style={{ width: '80px' }}
            disabled={paymentSaved}
          />
        </td>
        <td>
          {(parseFloat(cuota.monto_cat || 0) - parseFloat(cuota.descuento_cat || 0)).toFixed(2)} Bs.
        </td>
        <td>{parseFloat(cuota.monto_cab || 0).toFixed(2)} Bs.</td>
        <td>
          <Form.Control
            type="number"
            min="0"
            step="0.01"
            value={cuota.descuento_cab}
            onChange={(e) =>
              handleCuotaChange(index, 'descuento_cab', parseFloat(e.target.value) || 0)
            }
            style={{ width: '80px' }}
            disabled={paymentSaved}
          />
        </td>
        <td>
          {(parseFloat(cuota.monto_cab || 0) - parseFloat(cuota.descuento_cab || 0)).toFixed(2)} Bs.
        </td>
        <td>
          <Form.Control
            type="text"
            value={cuota.observacion}
            onChange={(e) =>
              handleCuotaChange(index, 'observacion', e.target.value)
            }
            style={{ width: '200px' }}
            placeholder=""
            disabled={
              paymentSaved || (!cuota.descuento_cat && !cuota.descuento_cab)
            }
          />
        </td>
      </tr>
    ))
  )}
</tbody>


  <tfoot>
  <tr>
    <td colSpan="3" style={{ textAlign: 'right' }}><strong>Totales:</strong></td>
    <td></td> {/* Columna Descuento CAT */}
    <td>
      <strong>
        {cuotasEditable
          .filter((_, index) => selectedCuotas[index]) // Solo seleccionadas
          .reduce(
            (total, cuota) =>
              total + (parseFloat(cuota.monto_cat || 0) - parseFloat(cuota.descuento_cat || 0)),
            0
          )
          .toFixed(2)}{' '}
        Bs.
      </strong>
    </td>
    <td></td> {/* Columna Descuento CAB */}
    <td></td> {/* Columna Descuento CAB */}
    <td>
      <strong>
        {cuotasEditable
          .filter((_, index) => selectedCuotas[index]) // Solo seleccionadas
          .reduce(
            (total, cuota) =>
              total + (parseFloat(cuota.monto_cab || 0) - parseFloat(cuota.descuento_cab || 0)),
            0
          )
          .toFixed(2)}{' '}
        Bs.
      </strong>
    </td>
    <td></td> {/* Observación */}
  </tr>
</tfoot>
</Table>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={handleProforma} disabled={!botonProformaHabilitado}  type="button">
            Proforma
          </Button>
          <Button variant="primary" onClick={handlePay} disabled={!botonGrabarHabilitado}>
            Pagar Cuotas
          </Button>
        </Modal.Footer>
      </Modal>

      {showEpsonPrintModal && (
        <EpsonPrintModal
          show={showEpsonPrintModal}
          handleClose={() => setShowEpsonPrintModal(false)}
          content={proformaContent} 
        />
      )}

      
      {showCameraModal && (
        <Modal show={showCameraModal} onHide={() => setShowCameraModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Capturar Imagen</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <video ref={videoRef} style={{ width: '100%' }} />
            <Form.Group className="mt-3">
              <Form.Label>Nombre del Archivo</Form.Label>
              <Form.Control
                type="text"
                value={nombreArchivo}
                onChange={(e) => setNombreArchivo(e.target.value)}
                placeholder="Ingrese el nombre del archivo"
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowCameraModal(false)}>
              Cerrar
            </Button>
            <Button variant="primary" onClick={captureImage}>
              Capturar Imagen
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default PagoCuotasModal;
