import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import jsPDF from 'jspdf';
import cabeceraCert from '../../assets/cabeceraCat.png';
import { useSelector, useDispatch } from 'react-redux';

const CustomPrintCert1 = ({ show, handleClose, afiliado, numeroCertificado, aserv, qrDataURL }) => {
    const [pdfUrl, setPdfUrl] = useState(null);
    const { nombre, usuario,rolActivo, roles,id } = useSelector(state => state.sesion);
    useEffect(() => {
        if (show) {
            generatePDF();
        }
    }, [show]);

    const generatePDF = () => {
        const doc = new jsPDF({ orientation: 'portrait', unit: 'px', format: 'letter' });

        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const marginTop = 40;
        const marginLeftRight = 40;
        const marginBottom = 60;

        const day = new Date().getDate();
        const month = new Date().toLocaleString('es-ES', { month: 'long' });
        const year = new Date().getFullYear();

        const pronoun = afiliado.persona.genero === 'FEMENINO' ? 'la' : 'el';
        const pronounInteresado = afiliado.persona.genero === 'FEMENINO' ? 'a la interesada' : 'al interesado';
        const pronounMismo = afiliado.persona.genero === 'FEMENINO' ? 'la misma' : 'el mismo';

        // Cabecera
        doc.addImage(cabeceraCert, 'PNG', marginLeftRight, marginTop, pageWidth - marginLeftRight * 2, 50);

        // Número de Certificado
        doc.setFont('times', 'bold');
        doc.setFontSize(14);
        doc.text(`Nº ${numeroCertificado}`, pageWidth - marginLeftRight, marginTop + 20, { align: 'right' });

        // Título
        doc.setFont('times', 'bold');
        doc.setFontSize(18);
        doc.text('CERTIFICADO', pageWidth / 2, marginTop + 80, { align: 'center' });

        doc.setFont('times', 'normal');
        doc.setFontSize(12);
        doc.text('El Colegio de Arquitectos de Tarija.', marginLeftRight, marginTop + 100);

        // Certificación
        doc.setFont('times', 'bold');
        doc.setFontSize(18);
        doc.text('CERTIFICA:', marginLeftRight, marginTop + 140);

        doc.setFont('times', 'normal');
        doc.setFontSize(12);
        doc.text(
            `Que, ${pronoun} ARQ. ${afiliado.persona.nombre} ${afiliado.persona.apellido1} ${afiliado.persona.apellido2}, con C.I. ${afiliado.persona.ci}, con registro Nacional Nº ${afiliado.num_reg}, está ejerciendo la profesión de Arquitecto desde el ${new Date(afiliado.fecha_reg_cab).toLocaleDateString('es-ES')} (${aserv}) de servicio activo, con todas las prerrogativas que establece la ley 1373 del Ejercicio Profesional.`,
            marginLeftRight,
            marginTop + 160,
            { maxWidth: pageWidth - marginLeftRight * 2, align: 'justify' }
        );

        doc.text(
            `Se certifica que ${pronounMismo} no cuenta con deuda en nuestra Institución, tanto en el Colegio Departamental como en el Colegio Nacional.`,
            marginLeftRight,
            marginTop + 220,
            { maxWidth: pageWidth - marginLeftRight * 2, align: 'justify' }
        );

        doc.text(
            `Es cuanto certificamos, en honor a la verdad y para fines que convenga ${pronounInteresado}.`,
            marginLeftRight,
            marginTop + 260,
            { maxWidth: pageWidth - marginLeftRight * 2, align: 'justify' }
        );

        // Fecha
        doc.text(`Tarija, ${day} de ${month} de ${year}`, marginLeftRight, marginTop + 300);

        // C/Archivo
        doc.text('C /Archivo', marginLeftRight, marginTop + 320);

        // QR Code
        if (qrDataURL) {
            doc.addImage(qrDataURL, 'PNG', pageWidth - marginLeftRight - 100, pageHeight - marginBottom - 100, 80, 80);
        }

        // Pie - Validez del certificado
        doc.setFont('times', 'bold');
        doc.setFontSize(10);
        doc.text('El presente certificado tiene validez de 30 días.', pageWidth / 2, pageHeight - marginBottom, { align: 'center' });
        doc.text('Arquitectura Atribución de Arquitectos.', pageWidth / 2, pageHeight - marginBottom + 10, { align: 'center' });

        // Pie adicional
        doc.setFont('times', 'normal');
        doc.setFontSize(8);
        doc.text(
            'Calle La Madrid # 615 casi Esq. Junín - Celular.: (591-4)6640066 - cat.tarija@gmail.com',
            pageWidth / 2,
            pageHeight - marginBottom + 30,
            { align: 'center' }
        );
        doc.text('Tarija - Bolivia', pageWidth / 2, pageHeight - marginBottom + 40, { align: 'center' });
        doc.text(
            `Impreso el: ${new Date().toLocaleString()} | Usuario: ${usuario || 'Desconocido'}`,
            pageWidth - marginLeftRight,
            pageHeight - marginBottom + 50,
            { align: 'right' }
        );

        // Guardar y generar PDF URL
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfUrl);
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Vista Previa del Certificado</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {pdfUrl ? (
                    <iframe src={pdfUrl} width="100%" height="500px" title="Vista previa del certificado" />
                ) : (
                    <p>Cargando vista previa...</p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={() => window.open(pdfUrl, '_blank')}>
                    Descargar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CustomPrintCert1;
