import React, { useRef, useState } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { QRCodeCanvas } from 'qrcode.react';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { updateValoradoVen } from '../../reducers/valoradoVen_slice';
import CustomPrintCert1 from './CustomPrintCert1';
import { calculateAge } from '../../util/valueCalculator';

const AfiliadoCert1 = ({ show, handleClose, afiliado, certificadoData }) => {
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [qrDataURL, setQrDataURL] = useState('');
    const [printDisabled, setPrintDisabled] = useState(false); // Estado para inhabilitar el botón de imprimir
    const qrRef = useRef();
    const dispatch = useDispatch();

    const { idValoradoVen, numeroCertificado, usado } = certificadoData;

    // Calcular años, meses y días de servicio
    const tiempoServicio = calculateAge(afiliado.fecha_reg_cab, new Date());

    const handleGenerateQR = () => {
        const canvas = qrRef.current.querySelector('canvas');
        const qrImageData = canvas.toDataURL('image/png');
        setQrDataURL(qrImageData);
    };

    const handlePrintConfirmation = async () => {
        const result = await Swal.fire({
            title: 'Confirmación',
            text: 'Este proceso es irreversible. Por favor, verifica los datos antes de proceder.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
        });

        if (result.isConfirmed) {
            try {
                // Actualizar el valorado_ven
                const updateResponse = await dispatch(
                    updateValoradoVen({ id: idValoradoVen, usado: usado - 1 })
                ).unwrap();

                if (updateResponse) {
                    // Generar QR y proceder con la impresión
                    handleGenerateQR();
                    setShowPrintModal(true);
                }
            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    text: 'No se pudo actualizar el certificado. Intenta nuevamente.',
                    icon: 'error',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    };

    const handlePrint = () => {
        handlePrintConfirmation();
    };

    const handlePrintModalClose = () => {
        setShowPrintModal(false);
        setPrintDisabled(true); // Inhabilitar el botón de imprimir al cerrar el modal de impresión
    };

    return (
        <>
            <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Detalles del Certificado 1</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mb-4">
                        <Col>
                            <h5>Datos del Afiliado</h5>
                            <p><strong>Nombre:</strong> {`${afiliado.persona.nombre} ${afiliado.persona.apellido1} ${afiliado.persona.apellido2}`}</p>
                            <p><strong>Número de Registro:</strong> {afiliado.num_reg}</p>
                            <p><strong>CI:</strong> {afiliado.persona.ci}</p>
                            <p><strong>Tiempo de Servicio:</strong> {`${tiempoServicio.years} años, ${tiempoServicio.months} meses, y ${tiempoServicio.days} días`}</p>
                        </Col>
                        <Col className="text-center" ref={qrRef}>
                            <h5>QR del Certificado</h5>
                            <QRCodeCanvas
                                value={`Certificado: ${numeroCertificado}, Nombre: ${afiliado.persona.nombre}, Registro: ${afiliado.num_reg}`}
                                size={128}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handlePrint}
                        disabled={printDisabled} // Botón inhabilitado si ya se imprimió
                    >
                        Imprimir
                    </Button>
                </Modal.Footer>
            </Modal>

            {showPrintModal && (
                <CustomPrintCert1
                    show={showPrintModal}
                    handleClose={handlePrintModalClose} // Inhabilitar el botón al cerrar este modal
                    afiliado={afiliado}
                    numeroCertificado={numeroCertificado}
                    aserv={`${tiempoServicio.years} años y ${tiempoServicio.months} meses`}
                    qrDataURL={qrDataURL}
                />
            )}
        </>
    );
};

export default AfiliadoCert1;
