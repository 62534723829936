import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPlanCuentas } from '../../reducers/planCuentas_slice';
import { fetchAllComprobantesWithDetails } from '../../reducers/comprobante_slice';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import CustomPrintModal from '../UI/CustomPrintModal'; // Ajustar la ruta si es diferente
import { useNavigate } from 'react-router-dom';
import BalanceGeneralModal from './BalanceGeneralModal'; // Asegúrate de que la ruta sea correcta
import { fechaActualBoliviaOnly, numeroALetras } from '../../util/valueCalculator';

const PlanCuentasView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const planCuentas = useSelector((state) => state.planCuentas.planCuentas || []);
  const comprobantes = useSelector((state) => state.comprobante?.comprobantes || []);
  const [expandedCuentas, setExpandedCuentas] = useState({});
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [fechaInicio, setFechaInicio] = useState('');
  const [fechaFinal, setFechaFinal] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false); // Controla el botón "Actualizar"
  const [showPrintModal, setShowPrintModal] = useState(false); // Controla el modal de impresión
  const [showBalanceModal, setShowBalanceModal] = useState(false); // Estado para el BalanceGeneralModal
  const [printContent, setPrintContent] = useState({ head: [], body: [] }); // Contenido para imprimir
  const [includeZeroBalances, setIncludeZeroBalances] = useState(true); // Estado para incluir/excluir cuentas con debe y haber en 0

  useEffect(() => {
    dispatch(fetchPlanCuentas());
    dispatch(fetchAllComprobantesWithDetails());
    setDefaultDates();
  }, [dispatch]);

  const handleOpenBalanceModal = () => {
    setShowBalanceModal(true); // Muestra el modal
  };
  
  const handleCloseBalanceModal = () => {
    setShowBalanceModal(false); // Cierra el modal
  };

  const setDefaultDates = () => {
    console.log("Fecha=",fechaActualBoliviaOnly());
    const currentDate = fechaActualBoliviaOnly();
    //.toISOString().split('T')[0];
    setFechaInicio(currentDate);
    setFechaFinal(currentDate);
    console.log("fecha de bolivia=",fechaInicio,fechaFinal)

  };

  const handleExpandToggle = (id) => {
    setExpandedCuentas((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleDateChange = () => {
    setCanUpdate(true); // Habilitar el botón "Actualizar" al cambiar las fechas
  };

  const handleUpdate = () => {
    setShowUpdateModal(false);
    setIsUpdating(true);
    setCanUpdate(false); // Deshabilitar el botón "Actualizar" después de usarlo

    Swal.fire({
      title: 'Actualizando Plan de Cuentas...',
      html: 'Por favor espere mientras se actualizan los datos.',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    dispatch(fetchAllComprobantesWithDetails())
      .then(() => {
        Swal.fire('Éxito', 'Los datos se han actualizado correctamente.', 'success');
      })
      .catch(() => {
        Swal.fire('Error', 'Hubo un problema al actualizar los datos.', 'error');
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const calculateAccountBalances = (cuentas, comprobantes) => {
    const cuentasMap = {};

    cuentas.forEach((cuenta) => {
      cuentasMap[cuenta.codigo_cuenta] = {
        ...cuenta,
        debe: 0,
        haber: 0,
        saldo: 0,
      };
    });

    comprobantes.forEach((comprobante) => {
        // Convertir las fechas a objetos Date para comparaciones
        const fechaComprobante = comprobante.fecha;
        console.log("fechCompro=",fechaComprobante, "fechaINI=",fechaInicio, "Fecha final=",fechaFinal)
      
        // Verificar si la fecha del comprobante está en el rango
        if (fechaComprobante >= fechaInicio && fechaComprobante <= fechaFinal && comprobante.estado==="REGISTRADO") {
          comprobante.detalles.forEach((detalle) => {
            const cuenta = cuentasMap[detalle.codigo_cuenta];
            
            if (cuenta) {
              cuenta.debe += parseFloat(detalle.debe || 0);
              cuenta.haber += parseFloat(detalle.haber || 0);
      
              // Calcular saldo según la naturaleza de la cuenta
              if (cuenta.naturaleza_cuenta === 'DEBE') {
                cuenta.saldo = cuenta.debe - cuenta.haber;
              } else if (cuenta.naturaleza_cuenta === 'HABER') {
                cuenta.saldo = cuenta.haber - cuenta.debe;
              } else {
                console.warn(`Naturaleza desconocida para la cuenta: ${cuenta.codigo_cuenta}`);
                cuenta.saldo = 0; // Manejo de casos donde la naturaleza no sea válida
              }
            }
          });
        }
      });

    const mayorizar = (codigoPadre, debeOriginal, haberOriginal) => {
      if (!codigoPadre) return;

      const cuentaPadre = cuentasMap[codigoPadre];
      if (cuentaPadre) {
        cuentaPadre.debe += debeOriginal;
        cuentaPadre.haber += haberOriginal;
        if(cuentaPadre.naturaleza_cuenta==="DEBE"){cuentaPadre.saldo = cuentaPadre.debe - cuentaPadre.haber;}
        else{cuentaPadre.saldo = cuentaPadre.haber - cuentaPadre.debe ;}
        mayorizar(cuentaPadre.codigo_padre, debeOriginal, haberOriginal);
      }
    };

    const cuentasDetalle = Object.values(cuentasMap).filter(
      (cuenta) => cuenta.debe > 0 || cuenta.haber > 0
    );

    cuentasDetalle.forEach((cuenta) => {
      mayorizar(cuenta.codigo_padre, cuenta.debe, cuenta.haber);
    });

    return Object.values(cuentasMap);
  };

  const cuentasWithBalances = useMemo(
    () => calculateAccountBalances(planCuentas, comprobantes),
    [planCuentas, comprobantes]
  );
  const buildHierarchy = (cuentas, parentCodigo = null) => {
    return cuentas
      .filter((cuenta) => cuenta.codigo_padre === parentCodigo)
      .map((cuenta) => ({
        ...cuenta,
        subcuentas: buildHierarchy(cuentas, cuenta.codigo_cuenta),
      }));
  };
  const cuentasHierarchy = useMemo(() => {
    const filteredCuentas = includeZeroBalances
      ? cuentasWithBalances
      : cuentasWithBalances.filter((cuenta) => cuenta.debe !== 0 || cuenta.haber !== 0);
    return buildHierarchy(filteredCuentas);
  }, [cuentasWithBalances, includeZeroBalances]);

 
  const handlePrint = () => {
    const head = [['Código', 'Nombre de Cuenta', 'Debe', 'Haber', 'Saldo']];
    const filteredCuentas = includeZeroBalances
      ? cuentasWithBalances
      : cuentasWithBalances.filter((cuenta) => cuenta.debe !== 0 || cuenta.haber !== 0);
    const sortedCuentas = [...filteredCuentas].sort((a, b) =>
      a.codigo_cuenta.localeCompare(b.codigo_cuenta)
    );
    const body = sortedCuentas.map((cuenta) => [
      cuenta.codigo_cuenta,
      cuenta.nombre_cuenta,
      cuenta.debe.toFixed(2),
      cuenta.haber.toFixed(2),
      cuenta.saldo.toFixed(2),
    ]);

    setPrintContent({ head, body });
    setShowPrintModal(true);
};

  const renderCuentas = (cuentas, level = 0) => {
    return cuentas.map((cuenta) => (
      <React.Fragment key={cuenta.codigo_cuenta}>
        <tr>
          <td style={{ paddingLeft: `${level * 20}px` }}>
            {cuenta.subcuentas.length > 0 && (
              <button
                onClick={() => handleExpandToggle(cuenta.codigo_cuenta)}
                className="icon-button btn-sm"
              >
                {expandedCuentas[cuenta.codigo_cuenta] ? (
                  <i className="bi bi-chevron-up"></i>
                ) : (
                  <i className="bi bi-chevron-down"></i>
                )}
              </button>
            )}
            {cuenta.codigo_cuenta}
          </td>
          <td>{cuenta.nombre_cuenta}</td>
          <td className="text-end">{Number(cuenta.debe || 0).toFixed(2)}</td>
          <td className="text-end">{Number(cuenta.haber || 0).toFixed(2)}</td>
          <td className="text-end">{Number(cuenta.saldo || 0).toFixed(2)}</td>
        </tr>
        {expandedCuentas[cuenta.codigo_cuenta] && renderCuentas(cuenta.subcuentas, level + 1)}
      </React.Fragment>
    ));
  };

  const handleBack = () => {
    navigate('/admin');
  };

  return (
    <div className="list-container list0">
      <h1 className="mb-4">Plan de Cuentas</h1>

      <div className="list-container-header d-flex align-items-center gap-3">
        <div className="d-flex align-items-center gap-2">
          <button title="Volver" onClick={handleBack} className="icon-button btn-transparent">
            <i className="bi bi-arrow-left"></i>
          </button>
          <label className="d-flex align-items-center gap-2 mb-0">
            Fecha Inicio:
            <input
              type="date"
              value={fechaInicio}
              onChange={(e) => {
                setFechaInicio(e.target.value);
                handleDateChange();
              }}
              className="form-control"
            />
          </label>
          <label className="d-flex align-items-center gap-2 mb-0">
            Fecha Final:
            <input
              type="date"
              value={fechaFinal}
              onChange={(e) => {
                setFechaFinal(e.target.value);
                handleDateChange();
              }}
              className="form-control"
            />
          </label>
          <button
            title="Actualizar"
            onClick={() => setShowUpdateModal(true)}
            className="icon-button btn-transparent"
            disabled={!canUpdate}
          >
            <i className="bi bi-arrow-clockwise"></i> Actualizar
          </button>
          <button
            title={includeZeroBalances ? "Excluir cuentas con debe y haber en 0" : "Incluir cuentas con debe y haber en 0"}
            onClick={() => setIncludeZeroBalances(!includeZeroBalances)}
            className="icon-button btn-transparent"
          >
            <i className={includeZeroBalances ? "bi bi-eye" : "bi bi-eye-slash"}></i>
          </button>
        </div>
      </div>

      <div className="list-content">
        <div className="table-responsive" style={{ maxHeight: '500px', overflowY: 'auto' }}>
          <Table bordered hover size="sm">
            <thead style={{ backgroundColor: '#b2aea3' }}>
              <tr>
                <th>Código</th>
                <th>Nombre</th>
                <th className="text-end">Debe</th>
                <th className="text-end">Haber</th>
                <th className="text-end">Saldo</th>
              </tr>
            </thead>
            <tbody>{renderCuentas(cuentasHierarchy)}</tbody>
          </Table>
        </div>

        



        <div className="text-center mt-3">
            <Button variant="outline-secondary" onClick={handlePrint} disabled={canUpdate} className="me-3">
                <i className="bi bi-printer"></i> Imprimir Mayores
            </Button>
            <Button variant="outline-secondary" onClick={handleOpenBalanceModal} disabled={canUpdate}>
                <i className="bi bi-printer"></i> Imprimir Balance
            </Button>
            </div>
      </div>

      {/* Modal de confirmación */}
      <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Actualizar Plan de Cuentas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          La actualización es un proceso intensivo que utiliza todos los comprobantes
          del rango seleccionado. Por favor, proceda con cuidado.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUpdateModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
      <BalanceGeneralModal
        show={showBalanceModal} // Controla si el modal se muestra
        handleClose={handleCloseBalanceModal} // Proporciona una función para cerrarlo
        cuentasMap={cuentasHierarchy} // Pasa el cuentasMap como prop al modal
        fechaInicio={fechaInicio}
        fechaFinal={fechaFinal}
        />
      {/* Modal de impresión */}
      {showPrintModal && (
        <CustomPrintModal
            show={showPrintModal}
            handleClose={() => setShowPrintModal(false)}
            title1="MAYORES"
            title2={`DEL ${fechaInicio} AL ${fechaFinal}`} // Concatenar fechas dinámicamente
            content={printContent}
        />
      )}
    </div>
  );
};

export default PlanCuentasView;
