import { useEffect, useState } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchAfiliados } from "../../reducers/afiliado_slice";
import SelectAfiliado from "../afiliado/SelectAfiliado";
import SelectInmueble from "../inmueble/SelectInmueble";
import SelectPersonas from "../persona/SelectPersonas";

const initialState = {
    id_proyectista: "",
    cuotas_al_dia: false
}

const TabInvolucrados = ({
    datos,
    tipoProceso,
    permitirCambios,
    handleListaPropietariosChange,
    handleSelect,
    errors,
    rolActivo,
    verificaciones,
    cuotas
}) => {

    return (
        <>
          <Row>
                <Col>
                <SelectInmueble labelText="Inmueble" onSelect={handleSelect} 
                            inmuebleSelected={datos.inmueble} 
                            permitirEdicion={!(tipoProceso==="transferir")&& permitirCambios}
                            />
                </Col>
                <Col>

                <SelectPersonas
                labelText="Propietario(s)" onSelect={handleListaPropietariosChange} 
                personasSelected={datos.propietarios} simplePersona={true}
                errorFeedback={{show:(tipoProceso === "transferir" && errors.noCambioPropietario),
                                message:"Debe cambiar de propietario para realizar la transferencia."
                }}
                permitirEdicion={permitirCambios || tipoProceso==="transferir"}
            />
                </Col>
            </Row>

            <Row>
                <Col>
                <SelectAfiliado onSelect={handleSelect} afiliado={datos.proyectista} labelText='Proyectista'
                    permitirEdicion={!(tipoProceso==="transferir" || tipoProceso==="editar")&& permitirCambios && rolActivo?.alias !=="afiliado"}/>
                
                </Col>
            </Row>
            
            {verificaciones.proyectistaAportes && 
            <>
            {verificaciones.proyectistaAportes === "sin_pendientes"? <div className="alert alert-success" role="alert">
            El afiliado está al día con sus aportes
            </div>
            : <>
                <div className="alert alert-danger" role="alert">
            El afiliado tiene aportes pendientes
            </div>
            <div className="mt-3">
                <Form.Label>Cuotas Pendientes</Form.Label>
                <Table bordered hover size="sm">
                    <thead style={{ backgroundColor: '#b2aea3' }}>
                    <tr>
                        <th>Cuota</th>
                        <th>Monto CAT</th>
                        <th>Monto CAB</th>
                        <th>Total</th>
                        <th>Estado</th>
                    </tr>
                    </thead>
                    <tbody>
                    {cuotas.length === 0 ? (
                        <tr>
                        <td colSpan="6">No hay cuotas pendientes</td>
                        </tr>
                    ) : (
                        cuotas.map((cuota, index) => (
                        <tr key={cuota.id}>
                            <td>{cuota.cuota}</td>
                            <td >
                            {parseFloat(cuota.monto_cat || 0).toFixed(2)} Bs.
                            </td>
                            <td>
                            {parseFloat(cuota.monto_cab || 0).toFixed(2)} Bs.
                            </td>
                            <td >
                            {(parseFloat(cuota.monto_cat || 0) + parseFloat(cuota.monto_cab || 0)).toFixed(2)} Bs.
                            </td>
                            <td>{cuota.estado === 'pagada' ? 'Pagada' : 'No Pagada'}</td>
                        </tr>
                        ))
                    )}
                    </tbody>
                </Table>
                </div>
            </>}
            </>
            }
        </>
    )
}

export default TabInvolucrados;