import { createBrowserRouter } from "react-router-dom";
import RootLayout from "./pages/RootLayout";
import { tokenLoader, checkGerenteLoader, checkAuthLoader, checkLoaderByRol } from "./util/auth";
import PublicLayout from "./pages/PublicLayout";
import InicioPage from "./pages/Inicio";
import LoginForm from "./components/autenticacion/LoginForm";
import RegisterForm from "./components/autenticacion/RegisterForm";
import ErrorPage from "./pages/Error";
import AutenticacionPage from "./pages/Autenticacion";
import PaisList from "./components/pais/PaisList";
import LugarList from "./components/lugar/LugarList";
import PersonaList from './components/persona/PersonaList';
import InstitucionList from './components/institucion/InstitucionList'; 
import RequisitoList from "./components/requisito/RequisitoList";
import AfiliadoList from "./components/afiliado/AfiliadoList";
import AreaProyectoList from "./components/areaProyecto/AreaProyectoList";
import TipoProyectoList from "./components/tipoProyecto/TipoProyectoList";
import PlanCuentasList from "./components/plancuentas/PlanCuentasList";
import PlanCuentasView from "./components/plancuentas/PlanCuentasView";
import ComprobanteList from './components/comprobante/ComprobanteList';  // Import ComprobanteList component
import ValoradoList from './components/valorado/ValoradoList';
import ValoradoDetList from './components/valorado/ValoradoDetList';
import BalanceGeneral from './components/comprobante/BalanceGeneral';  // Import ComprobanteList component
import DashboardAdmin from "./pages/DashboardAdmin";
import ListaSituaciones from "./components/situacion/ListaSituaciones";
import ListaSeguimientosSituAfiliado from "./components/seguimientoSituAfiliado/ListaSeguimientosSituAfiliado";
import ListCategorias from "./components/categoria/ListCategorias";
import ListTipologias from "./components/tipologia/ListTipologias";
import ListProyectos from "./components/proyecto/ListProyectos";
import ListPropietarios from "./components/propietario/ListPropietarios";
import GestionInmuebles from "./components/inmueble/GestionInmuebles";
import GestionAfiliados from "./components/afiliado/GestionAfiliados";
import ListaAfiliados from "./components/cuota/ListaAfiliados";
import GenerarCuotas from "./components/cuota/GenerarCuotas";
import BancoList from "./components/banco/BancoList";
import ValoradoVenList from './components/valorado/ValoradoVenList';
import IngresosVariosList from './components/ingresosVarios/IngresosVariosList';
import ReciboList from './components/recibo/ReciboList';
import CajaList from './components/caja/CajaList.js';
import CajaDiariaList from './components/caja/CajaDiariaList.js';
import GestionInscripciones from "./components/afiliado/GestionInscripciones.js";
import ListUsuarios from "./components/usuario/ListUsuarios.js";

import ClavesMasivas from "./components/usuario/ClavesMasivas.js";

import ListRoles from "./components/rol/ListRoles.js";
import DatGeneralList from './components/datgeneral/DatGeneralList'; // Importa el componente
import RegionList from "./components/region/RegionList"; // Importar el componente RegionList
import ZonaList from "./components/zona/ZonaList";
import BackupList from "./components/backup/BackupList";
import GradoList from './components/grado/GradoList.js';
import DepositoList from './components/deposito/DepositoList.js';
import DirectivaList from './components/directiva/DirectivaList.js';
import CargoList from './components/cargo/CargoList.js';
import NoticiasList from './components/noticia/NoticiasList.js';
import PortadasList from './components/portada/PortadasList.js';
import AfiliadoCert from './components/afiliado/AfiliadoCert.js';
import AfiliadoReport from './components/afiliado/AfiliadoReport.js';
import AfiliadoEditForm from './components/afiliado/AfiliadoEditForm.js';
import AfiliadoEstadoCuenta from './components/afiliado/AfiliadoEstadoCuenta.js';

import ChangePasswordForm from './components/autenticacion/ChangePasswordForm.js';

// Dentro de las rutas administrativas



//import ListaInscripciones from "./components/inscripcion/ListaInscripciones";

const router = createBrowserRouter([
  {
    path: '/',
    element: <PublicLayout />,  // Layout para páginas públicas
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <InicioPage /> },  // Página de inicio público
      // { path: 'proyectos', element: <ListaProyectosPublicos /> },  // Proyectos visibles al público
    ],
  },
  {
    element: <AutenticacionPage />,
    children: [
      { path: 'login', element: <LoginForm /> },
      { path: 'register', element: <RegisterForm /> },
    ],
  },
  
  // Rutas internas para la directiva (acceso restringido por roles)
  {
    path: '/admin',  // O puedes usar '/directiva'
    element: <RootLayout />,  // Layout de la directiva
    errorElement: <ErrorPage />,
    loader: tokenLoader,  // Verifica si el usuario es de la directiva
    children: [
      { index: true, element: <DashboardAdmin />, },  // Resumen del panel de la directiva
      { path: 'afiliados', element: <GestionAfiliados />,   loader: () => checkLoaderByRol(["gerente","secretaria"]) },  // Gestión de afiliados
      { path: 'inscripciones', element: <GestionInscripciones />, loader: () => checkLoaderByRol(["gerente","secretaria"])  }, 
      { path: 'seguimiento-situacion-afiliados', element: <ListaSeguimientosSituAfiliado />, loader: () => checkLoaderByRol(["gerente"])  }, 
      { path: 'personas', element: <PersonaList />,         loader: () => checkLoaderByRol(["admin"]) },
      { path: 'paises', element: <PaisList /> ,             loader: () => checkLoaderByRol(["admin"]) },  
      { path: 'lugares', element: <LugarList />,            loader: () => checkLoaderByRol(["admin"])  },  
      { path: 'regiones',  element: <RegionList />,         loader: () => checkLoaderByRol(["admin"])   },
      { path: 'zonas',  element: <ZonaList />,              loader: () => checkLoaderByRol(["admin"])   },
      { path: 'instituciones', element: <InstitucionList />, loader: () => checkLoaderByRol(["admin"]) },  
      { path: 'requisitos', element: <RequisitoList />, loader: () => checkLoaderByRol(["gerente"]) },  
      { path: 'situaciones', element: <ListaSituaciones />, loader: () => checkLoaderByRol(["gerente"])  },  
      { path: 'rubros-proyecto', element: <AreaProyectoList />, loader: () => checkLoaderByRol(["admin"]) },
      { path: 'tipos-proyecto', element: <TipoProyectoList />, loader: () => checkLoaderByRol(["admin"]) },
      { path: 'categorias', element: <ListCategorias />, loader: () => checkLoaderByRol(["admin"]) },
      { path: 'inmuebles', element: <GestionInmuebles />, loader: () => checkLoaderByRol(["gerente"]) },
      // { path: 'propietarios', element: <ListPropietarios />, loader: () => checkLoaderByRol(["desa"])  },
      { path: 'tipologias', element: <ListTipologias />, loader: () => checkLoaderByRol(["admin"]) },
      { path: 'plan-cuentas', element: <PlanCuentasList />, loader: () => checkLoaderByRol(["contador"]) },  // Route for PlanCuentasList
      { path: 'valorados', element: <ValoradoList />, loader: () => checkLoaderByRol(["admin"]) },
      { path: 'valorado-vens', element: <ValoradoVenList />, loader: () => checkLoaderByRol(["secretaria"]) }, 
      { path: 'valorado-dets', element: <ValoradoDetList />, loader: () => checkLoaderByRol(["secretaria"]) },
      { path: 'comprobantes', element: <ComprobanteList />, loader: () => checkLoaderByRol(["contador"]) },   // New route for ComprobanteList
      // { path: 'balance-general', element: <BalanceGeneral /> } ,
      { path: 'pago-cuotas/:codigoFuncionalidad', element: <ListaAfiliados />, loader: () => checkLoaderByRol(["secretaria","contador"]) },
      { path: 'ingresos-varios', element: <IngresosVariosList />, loader: () => checkLoaderByRol(["secretaria"]) } , 
      { path: 'otros-ingresos', element: <ReciboList />, loader: () => checkLoaderByRol(["secretaria"]) } , 
      // { path: 'cajas', element: <CajaList />, loader: () => checkLoaderByRol(["desa"]) } , 
      { path: 'bancos', element: <BancoList />, loader: () => checkLoaderByRol(["contador"]) } , 
      { path: 'caja-diarias', element: <CajaDiariaList />, loader: () => checkLoaderByRol(["secretaria"]) } , 
      { path: 'generar-cuotas', element: <GenerarCuotas />, loader: () => checkLoaderByRol(["gerente"]) } , 
      // { path: 'ventas', element: <VentasList /> },  // Gestión de ventas de valorados
      // { path: 'contabilidad', element: <Contabilidad /> },  // Gestión de contabilidad
     { path: 'proyectos', element: <ListProyectos />,       loader: () => checkLoaderByRol(["gerente","secretaria"]) },  // Gestión de propiedades intelectuales
     { path: 'mis-proyectos', element: <ListProyectos />,   loader: () => checkLoaderByRol(["afiliado"]) },

     { path: 'usuarios', element: <ListUsuarios />,         loader: () => checkLoaderByRol(["admin"]) },  // Gestión de propiedades intelectuales
     { path: 'roles', element: <ListRoles/>,                loader: () => checkLoaderByRol(["admin"])}, 
     { path: 'dat-general', element: <DatGeneralList />, loader: () => checkLoaderByRol(["admin"]) },  // Nueva ruta para DatGeneralList
     { path: 'backups',  element: <BackupList />, loader: () => checkLoaderByRol(["admin"])  },
     { path: 'grados',  element: <GradoList />, loader: () => checkLoaderByRol(["admin"])  },
     { path: 'cargos',  element: <CargoList />, loader: () => checkLoaderByRol(["admin"])  },
     { path: 'mayorizacion',  element: <PlanCuentasView />, loader: () => checkLoaderByRol(["gerente", "contador"])  },
     { path: 'directivas',  element: <DirectivaList />, loader: () => checkLoaderByRol(["admin"])  },
     { path: 'noticias',  element: <NoticiasList />, loader: () => checkLoaderByRol(["admin", "gerente"])  },
     { path: 'portadas',  element: <PortadasList />, loader: () => checkLoaderByRol(["admin", "gerente"])  },
     { path: 'certificados',  element: <AfiliadoCert />, loader: () => checkLoaderByRol(["secretaria"])  },
     { path: 'actualiza-afiliados',  element: <AfiliadoEditForm />, loader: () => checkLoaderByRol(["afiliado"])  },
     { path: 'estado-afiliados',  element: <AfiliadoEstadoCuenta />, loader: () => checkLoaderByRol(["afiliado"])  },
     { path: 'change-password',  element: <ChangePasswordForm />, loader: () => checkLoaderByRol(["afiliado"]) },
     { path: 'afiliados-reportes',  element: <AfiliadoReport />, loader: () => checkLoaderByRol(["gerente"])  },
     { path: 'vista-depositos',  element: <DepositoList />, loader: () => checkLoaderByRol(["contador"])  },
     { path: 'claves-masivas',  element: <ClavesMasivas />, loader: () => checkLoaderByRol(["desa"])  },
    ],
  },
  
  // Rutas internas para asociados (gestión de proyectos)
  {
    path: '/asociados',  // Rubro exclusiva para los asociados
    element: <RootLayout />,  // Layout de asociados
    errorElement: <ErrorPage />,
    loader: tokenLoader,  // Verifica si el usuario está autenticado y es asociado
    children: [
      // { index: true, element: <DashboardAsociados /> },  // Panel general de proyectos
      // { path: 'mis-proyectos', element: <MisProyectosList /> },  // Lista de proyectos del asociado
      // { path: 'proyectos/nuevo', element: <NuevoProyectoForm /> },  // Registro de un nuevo proyecto
      // { path: 'proyectos/:id', element: <ProyectoDetail /> },  // Detalles de un proyecto específico
    ],
  },
]);

export default router;
