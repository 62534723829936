import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { formatFecha, numeroALetras } from '../../util/valueCalculator';
import ImprimirRecibo from '../recibo/ImprimirRecibo';
import * as XLSX from 'xlsx';
import CustomPrintModal from '../UI/CustomPrintModal3';
import { Modal, Table, Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { fetchReciboById } from '../../reducers/recibo_slice';
import { fetchSituaciones } from '../../reducers/situacion_slice';

const AfiliadoEstadoCuentaModal = ({ show, handleClose, id_afiliado }) => {
    const dispatch = useDispatch();

    // State Selectors
    const cuotas = useSelector((state) => state.cuota.cuotas || []);
    const afiliados = useSelector((state) => state.afiliado.afiliados || []);
    const situaciones = useSelector((state) => state.situacion.situaciones || []);

    const afiliado = afiliados.find((a) => a.id === id_afiliado);

    // Local State
    const [startDate, setStartDate] = useState(`${new Date().getFullYear()}-01-01`);
    const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
    const [filterStatus, setFilterStatus] = useState('no_pagada');
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [printContent, setPrintContent] = useState({ head: [], body: [] });
    const [reciboData, setReciboData] = useState(null);
    const [showReciboModal, setShowReciboModal] = useState(false);
    console.log("Afiliados=",afiliados,"  Afiliado=",afiliado)
    // Filter cuotas by afiliado and other criteria

    useEffect(() => {
       
        dispatch(fetchSituaciones());
    }, [dispatch, id_afiliado]);

    const filteredCuotas = useMemo(() => {
        return cuotas.filter((cuota) => {
            const isInDateRange =
                (!startDate || new Date(cuota.fecha_emision) >= new Date(startDate)) &&
                (!endDate || new Date(cuota.fecha_emision) <= new Date(endDate));
            const matchesStatus = filterStatus === 'todas' || cuota.estado === filterStatus;
            return cuota.id_afiliado === id_afiliado && isInDateRange && matchesStatus;
        });
    }, [cuotas, id_afiliado, startDate, endDate, filterStatus]);

    const handleExportExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(
            filteredCuotas.map((cuota) => ({
                Fecha: cuota.fecha_pago ? formatFecha(cuota.fecha_pago) : '',
                Cuota: cuota.cuota,
                Estado: cuota.estado,
                CuotaCat: cuota.monto_cat,
                DescCat: cuota.decuento_cat,
                PagadoCat: cuota.monto_cat_pagada,
                CuotaCab: cuota.monto_cab,
                DescCab: cuota.decuento_cab,
                PagadoCab: cuota.monto_cab_pagada,
            }))
        );
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Cuotas');
        XLSX.writeFile(workbook, `cuotas_afiliado_${id_afiliado}.xlsx`);
    };

    const handlePrint = () => {
        const content = {
            head: [['Fecha', 'Cuota', 'Estado', 'CuotaCat', 'DescCat', 'PagadoCat', 'CuotaCab', 'DescCab', 'PagadoCab']],
            body: filteredCuotas.map((cuota) => [
                cuota.fecha_pago ? formatFecha(cuota.fecha_pago) : '',
                cuota.cuota,
                cuota.estado,
                cuota.monto_cat,
                cuota.decuento_cat,
                cuota.monto_cat_pagada,
                cuota.monto_cab,
                cuota.decuento_cab,
                cuota.monto_cab_pagada,
            ]),
        };
        setPrintContent(content);
        setShowPrintModal(true);
    };

    const handleViewRecibo = async (id_recibo) => {
        try {
            const recibo = await dispatch(fetchReciboById(id_recibo)).unwrap();
            const totalLiteral = numeroALetras(recibo.monto);

            setReciboData({
                recibo,
                reciboDetalles: recibo.reciboDets,
                totalLiteral,
            });
            setShowReciboModal(true);
        } catch (error) {
            Swal.fire('Error', 'No se pudo obtener el recibo', 'error');
        }
    };

    return (
        <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Estado de Cuenta</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {afiliado && (
                    <Row>
                        <Col md={6}>
                            <p>
                                <strong>Apellidos y Nombre:</strong> {`${afiliado.persona.apellido1} ${afiliado.persona.apellido2}, ${afiliado.persona.nombre}`}
                            </p>
                        </Col>
                        <Col md={4}>
                            <p>
                                <strong>C.I.:</strong> {afiliado.persona.ci}
                            </p>
                        </Col>
                        <Col md={4}>
                            <p>
                                <strong>Número de Registro:</strong> {afiliado.num_reg}
                            </p>
                        </Col>
                        <Col md={4}>
                            <p>
                                <strong>Situación:</strong> {situaciones.find((s) => s.id === afiliado.id_situacion)?.nombre || 'No disponible'}
                            </p>
                        </Col>
                    </Row>
                )}

                <section>
                    <h4>Cuotas</h4>
                    <Table bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Cuota</th>
                                <th>Estado</th>
                                <th>MontoCat</th>
                                <th>DescCat</th>
                                <th>PagadoCat</th>
                                <th>MontoCab</th>
                                <th>DescCab</th>
                                <th>PagadoCab</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredCuotas.map((cuota) => (
                                <tr key={cuota.id}>
                                    <td>{cuota.fecha_emision ? formatFecha(cuota.fecha_emision) : ''}</td>
                                    <td>{cuota.cuota}</td>
                                    <td>{cuota.estado}</td>
                                    <td>{cuota.monto_cat}</td>
                                    <td>{cuota.decuento_cat}</td>
                                    <td>{cuota.monto_cat_pagada}</td>
                                    <td>{cuota.monto_cab}</td>
                                    <td>{cuota.decuento_cab}</td>
                                    <td>{cuota.monto_cab_pagada}</td>
                                    <td>
                                        {cuota.estado === 'pagada' && (
                                            <button
                                                className="icon-button btn-secondary-outline"
                                                onClick={() => handleViewRecibo(cuota.id_recibo)}
                                            >
                                                <i className="bi bi-receipt"></i>
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </section>

                {reciboData && (
                    <ImprimirRecibo
                        show={showReciboModal}
                        handleClose={() => setShowReciboModal(false)}
                        recibo={reciboData.recibo}
                        reciboDetalles={reciboData.reciboDetalles}
                        totalLiteral={reciboData.totalLiteral}
                    />
                )}

                <CustomPrintModal
                    show={showPrintModal}
                    handleClose={() => setShowPrintModal(false)}
                    title1="ESTADO DE CUOTAS"
                    content={printContent}
                />
            </Modal.Body>
        </Modal>
    );
};

export default AfiliadoEstadoCuentaModal;
